import {
  Autocomplete,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getRiskyCustomerRiskSegment,
  getRiskyCustomerPaydex,
  getRiskyCustomerRiskScore,
} from "./utils/HelperRC";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import dollar from "./Assets/Images/dollar.svg";
import company from "./Assets/Images/company.svg";
import { processAgingData } from "./utils/Helper";
import { useUserPreferences } from "./context/userPreferencesContext";

function formatNumber(value) {
  return isNaN(value) || !isFinite(value)
    ? "0"
    : Math.round(value).toLocaleString();
}

const getTotalForAmountReceivableRiskSegment = (data, agingDays) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    agingData: {},
    totalRS2Point5: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalRS5: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalRS7Point5: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalRS7Point5Greater: { currentARBalance: 0, customerWithCurrentAR: 0 },
  };

  for (const key in agingDays) {
    const day = agingDays[key];
    totals.agingData[day] = { total: 0, customerCount: 0 };
    totals.totalRS2Point5[day] = { total: 0, customerCount: 0 };
    totals.totalRS5[day] = { total: 0, customerCount: 0 };
    totals.totalRS7Point5[day] = { total: 0, customerCount: 0 };
    totals.totalRS7Point5Greater[day] = { total: 0, customerCount: 0 };
  }

  data.forEach((item) => {
    if (item["Total AR Balance"]) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"]) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;

      if (item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0) {
        totals.totalRS2Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS2Point5.customerWithCurrentAR += 1;
      } else if (item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5) {
        totals.totalRS5.currentARBalance += item["Current AR Balance"];
        totals.totalRS5.customerWithCurrentAR += 1;
      } else if (item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5) {
        totals.totalRS7Point5.currentARBalance += item["Current AR Balance"];
        totals.totalRS7Point5.customerWithCurrentAR += 1;
      } else if (item["Risk Segment"] > 7.5) {
        totals.totalRS7Point5Greater.currentARBalance += item["Current AR Balance"];
        totals.totalRS7Point5Greater.customerWithCurrentAR += 1;
      }
    }

    const processedAgingData = processAgingData(item, agingDays);
    console.log("Processed Aging Data-> ", processedAgingData);

    for (const key in agingDays) {
      const day = agingDays[key];
      const agingValue = processedAgingData?.[day]; 

      if (agingValue) {
        totals.agingData[day].total += agingValue;
        totals.agingData[day].customerCount += 1;

        if (item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0) {
          totals.totalRS2Point5[day].total += agingValue;
          totals.totalRS2Point5[day].customerCount += 1;
        } else if (item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5) {
          totals.totalRS5[day].total += agingValue;
          totals.totalRS5[day].customerCount += 1;
        } else if (item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5) {
          totals.totalRS7Point5[day].total += agingValue;
          totals.totalRS7Point5[day].customerCount += 1;
        } else if (item["Risk Segment"] > 7.5) {
          totals.totalRS7Point5Greater[day].total += agingValue;
          totals.totalRS7Point5Greater[day].customerCount += 1;
        }
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalRS2Point5: formatNumber(totals.totalRS2Point5.currentARBalance),
      customerRS2Point5: formatNumber(totals.totalRS2Point5.customerWithCurrentAR),
      totalRS5: formatNumber(totals.totalRS5.currentARBalance),
      customerRS5: formatNumber(totals.totalRS5.customerWithCurrentAR),
      totalRS7Point5: formatNumber(totals.totalRS7Point5.currentARBalance),
      customerRS7Point5: formatNumber(totals.totalRS7Point5.customerWithCurrentAR),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.currentARBalance),
      customerRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater.customerWithCurrentAR),
    },
  ];

  const orderedAgingDays = [
    agingDays.age1,  
    agingDays.age2,  
    agingDays.age3   
  ];

  orderedAgingDays.forEach((day, index) => {
    rowDataAR.push({
      id: index + 2,
      metric: `AR Balance Aging ${day}`,
      customer: formatNumber(totals.agingData[day]?.customerCount || 0),
      total: formatNumber(totals.agingData[day]?.total || 0),
      totalRS2Point5: formatNumber(totals.totalRS2Point5[day]?.total || 0),
      customerRS2Point5: formatNumber(totals.totalRS2Point5[day]?.customerCount || 0),
      totalRS5: formatNumber(totals.totalRS5[day]?.total || 0),
      customerRS5: formatNumber(totals.totalRS5[day]?.customerCount || 0),
      totalRS7Point5: formatNumber(totals.totalRS7Point5[day]?.total || 0),
      customerRS7Point5: formatNumber(totals.totalRS7Point5[day]?.customerCount || 0),
      totalRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater[day]?.total || 0),
      customerRS7Point5Greater: formatNumber(totals.totalRS7Point5Greater[day]?.customerCount || 0),
    });
  });

  return rowDataAR;
};

const getTotalForAmountReceivablePaydex = (data, agingDays) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    agingMetrics: {},
    totalLow: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalMedium: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalHigh: { currentARBalance: 0, customerWithCurrentAR: 0 },
  };

  for (const key in agingDays) {
    const day = agingDays[key];
    totals.agingMetrics[day] = { total: 0, customerCount: 0 };
    totals.totalLow[day] = { total: 0, customerCount: 0 };
    totals.totalMedium[day] = { total: 0, customerCount: 0 };
    totals.totalHigh[day] = { total: 0, customerCount: 0 };
  }

  data.forEach((item) => {
    if (item["Total AR Balance"]) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"]) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;

      if (item["Paydex"] >= 1 && item["Paydex"] < 4) {
        totals.totalLow.currentARBalance += item["Current AR Balance"];
        totals.totalLow.customerWithCurrentAR += 1;
      } else if (item["Paydex"] >= 4 && item["Paydex"] < 7) {
        totals.totalMedium.currentARBalance += item["Current AR Balance"];
        totals.totalMedium.customerWithCurrentAR += 1;
      } else if(item["Paydex"] >= 7){
        totals.totalHigh.currentARBalance += item["Current AR Balance"];
        totals.totalHigh.customerWithCurrentAR += 1;
      }
    }

    const processedAgingData = processAgingData(item, agingDays);

    for (const key in agingDays) {
      const day = agingDays[key];
      const agingValue = processedAgingData[day];

      if (agingValue) {
        totals.agingMetrics[day].total += agingValue;
        totals.agingMetrics[day].customerCount += 1;

        if (item["Paydex"] >= 1 && item["Paydex"] < 4) {
          totals.totalLow[day].total += agingValue;
          totals.totalLow[day].customerCount += 1;
        } else if (item["Paydex"] >= 4 && item["Paydex"] < 7) {
          totals.totalMedium[day].total += agingValue;
          totals.totalMedium[day].customerCount += 1;
        }  else if(item["Paydex"] >= 7){
          totals.totalHigh[day].total += agingValue;
          totals.totalHigh[day].customerCount += 1;
        }
      }
    }
  });

  const rowDataPaydex = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalLow: formatNumber(totals.totalLow.currentARBalance),
      customerLow: formatNumber(totals.totalLow.customerWithCurrentAR),
      totalMedium: formatNumber(totals.totalMedium.currentARBalance),
      customerMedium: formatNumber(totals.totalMedium.customerWithCurrentAR),
      totalHigh: formatNumber(totals.totalHigh.currentARBalance),
      customerHigh: formatNumber(totals.totalHigh.customerWithCurrentAR),
    },
  ];

  const orderedAgingDays = [agingDays.age1, agingDays.age2, agingDays.age3];

  orderedAgingDays.forEach((day, index) => {
    if (totals.agingMetrics[day]) {
      rowDataPaydex.push({
        id: index + 2,
        metric: `AR Balance Aging ${day}`,
        customer: formatNumber(totals.agingMetrics[day].customerCount),
        total: formatNumber(totals.agingMetrics[day].total),
        totalLow: formatNumber(totals.totalLow[day].total),
        customerLow: formatNumber(totals.totalLow[day].customerCount),
        totalMedium: formatNumber(totals.totalMedium[day].total),
        customerMedium: formatNumber(totals.totalMedium[day].customerCount),
        totalHigh: formatNumber(totals.totalHigh[day].total),
        customerHigh: formatNumber(totals.totalHigh[day].customerCount),
      });
    }
  });

  return rowDataPaydex;
};


const getTotalForAmountReceivableRiskScore = (data, agingDays) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    agingData: {},
    totalLow: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalMedium: { currentARBalance: 0, customerWithCurrentAR: 0 },
    totalHigh: { currentARBalance: 0, customerWithCurrentAR: 0 },
  };

  for (const key in agingDays) {
    const day = agingDays[key];
    totals.agingData[day] = { total: 0, customerCount: 0 };
    totals.totalLow[day] = { total: 0, customerCount: 0 };
    totals.totalMedium[day] = { total: 0, customerCount: 0 };
    totals.totalHigh[day] = { total: 0, customerCount: 0 };
  }

  data.forEach((item) => {
    if (item["Total AR Balance"]) {
      totals.totalARBalance += item["Total AR Balance"];
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"]) {
      totals.currentARBalance += item["Current AR Balance"];
      totals.customerWithCurrentAR += 1;

      if (item["General Risk Score"] >= 1 && item["General Risk Score"] < 4) {
        totals.totalLow.currentARBalance += item["Current AR Balance"];
        totals.totalLow.customerWithCurrentAR += 1;
      } else if (item["General Risk Score"] >= 4 && item["General Risk Score"] < 7) {
        totals.totalMedium.currentARBalance += item["Current AR Balance"];
        totals.totalMedium.customerWithCurrentAR += 1;
      } else if (item["General Risk Score"] >= 7 && item["General Risk Score"] <= 10) {
        totals.totalHigh.currentARBalance += item["Current AR Balance"];
        totals.totalHigh.customerWithCurrentAR += 1;
      }
    }

    const processedAgingData = processAgingData(item, agingDays); 
    console.log("Processed Aging Data->", item["Company Name"], processedAgingData);

    for (const key in agingDays) {
      const day = agingDays[key];

      if (processedAgingData && processedAgingData[day]) {
        const agingValue = processedAgingData[day]; 

        totals.agingData[day].total += agingValue;
        totals.agingData[day].customerCount += 1;

        if (item["General Risk Score"] >= 1 && item["General Risk Score"] < 4) {
          totals.totalLow[day].total += agingValue;
          totals.totalLow[day].customerCount += 1;
        } else if (item["General Risk Score"] >= 4 && item["General Risk Score"] < 7) {
          totals.totalMedium[day].total += agingValue;
          totals.totalMedium[day].customerCount += 1;
        } else if (item["General Risk Score"] >= 7 && item["General Risk Score"] <= 10) {
          totals.totalHigh[day].total += agingValue;
          totals.totalHigh[day].customerCount += 1;
        }
      }
    }
  });

  const rowDataRiskScore = [
    {
      id: 1,
      metric: "Current AR Balance",
      customer: formatNumber(totals.customerWithCurrentAR),
      total: formatNumber(totals.currentARBalance),
      totalLow: formatNumber(totals.totalLow.currentARBalance),
      customerLow: formatNumber(totals.totalLow.customerWithCurrentAR),
      totalMedium: formatNumber(totals.totalMedium.currentARBalance),
      customerMedium: formatNumber(totals.totalMedium.customerWithCurrentAR),
      totalHigh: formatNumber(totals.totalHigh.currentARBalance),
      customerHigh: formatNumber(totals.totalHigh.customerWithCurrentAR),
    },
  ];

  const orderedAgingDays = [
    agingDays.age1,  
    agingDays.age2,  
    agingDays.age3   
  ];

  orderedAgingDays.forEach((day, index) => {
    rowDataRiskScore.push({
      id: index + 2,  
      metric: `AR Balance Aging ${day}`,
      customer: formatNumber(totals.agingData[day]?.customerCount || 0),
      total: formatNumber(totals.agingData[day]?.total || 0), 
      totalLow: formatNumber(totals.totalLow[day]?.total || 0),
      customerLow: formatNumber(totals.totalLow[day]?.customerCount || 0),
      totalMedium: formatNumber(totals.totalMedium[day]?.total || 0),
      customerMedium: formatNumber(totals.totalMedium[day]?.customerCount || 0),
      totalHigh: formatNumber(totals.totalHigh[day]?.total || 0),
      customerHigh: formatNumber(totals.totalHigh[day]?.customerCount || 0),
    });
  });

  return rowDataRiskScore;
};

const xAxisOptions = [
  { id: 1, label: "Risk Score" },
  { id: 2, label: "Payment Index" },
  { id: 3, label: "Risk Segment" },
];

const ARHeatmapNew = ({ summary, adminAction }) => {
  const [selectedOption, setSelectedOption] = useState(xAxisOptions[0]);
  const [agingDays, setAgingDays] = useState({
    age1: "30",
    age2: "60",
    age3: "90",
  });
  const { userPreferences, updateUserPreferences, loading } = useUserPreferences();

  useEffect(() => {
    if (!loading) {
      const agingPreferences = userPreferences?.agingDays;
      if (agingPreferences) setAgingDays(agingPreferences);
    }
  }, [loading]);
  const handleDropdownChange = (event, newValue) => {
    if (newValue) {
      setSelectedOption(newValue);
    }
  };

  const getSegmentLabels = (type) => {
    switch (type) {
      case 1:
      case 2:
        return ["1-4 (Low)", "4-7 (Medium)", "7-10 (High)"];
      case 3:
        return [
          "0<=2.5 (Low)",
          "2.5-5 (Medium-Low)",
          "5-7.5 (Medium-High)",
          ">7.5 (High)",
        ];
      default:
        return [];
    }
  };

  const agingCategories = [
    `Current AR Balance`,
    `AR Balance Aging ${agingDays.age1}`,
    `AR Balance Aging ${agingDays.age2}`,
    `AR Balance Aging ${agingDays.age3}`,
  ];

  const constructData = (data, scoreType) => { 
    console.log("Input data-> ", JSON.stringify(data, null, 2));
    console.log("Score type-> ", scoreType);

    const segmentLabels = getSegmentLabels(selectedOption.id);
    console.log("Segment labels-> ", segmentLabels);

    let pivotedData = segmentLabels.map((label) => ({ segment: label }));

    if (scoreType === "Risk Segment") {
      agingCategories.forEach((category, index) => {
        const item = data[index] || {}; 
        pivotedData[0][category] = [
          parseFloat((item.totalRS2Point5 || "0").replace(/,/g, "")),
          parseInt((item.customerRS2Point5 || "0").replace(/,/g, "")),
        ];
        pivotedData[1][category] = [
          parseFloat((item.totalRS5 || "0").replace(/,/g, "")),
          parseInt((item.customerRS5 || "0").replace(/,/g, "")),
        ];
        pivotedData[2][category] = [
          parseFloat((item.totalRS7Point5 || "0").replace(/,/g, "")),
          parseInt((item.customerRS7Point5 || "0").replace(/,/g, "")),
        ];
        pivotedData[3][category] = [
          parseFloat((item.totalRS7Point5Greater || "0").replace(/,/g, "")),
          parseInt((item.customerRS7Point5Greater || "0").replace(/,/g, "")),
        ];
      });
    } else {
      agingCategories.forEach((category, index) => {
        const item = data[index] || {}; 
        pivotedData[0][category] = [
          parseFloat((item.totalLow || "0").replace(/,/g, "")),
          parseInt((item.customerLow || "0").replace(/,/g, "")),
        ];
        pivotedData[1][category] = [
          parseFloat((item.totalMedium || "0").replace(/,/g, "")),
          parseInt((item.customerMedium || "0").replace(/,/g, "")),
        ];
        pivotedData[2][category] = [
          parseFloat((item.totalHigh || "0").replace(/,/g, "")),
          parseInt((item.customerHigh || "0").replace(/,/g, "")),
        ];
      });
    }
    // Add total row
    const totalRow = { segment: "Total" };
    agingCategories.forEach((category) => {
      const totalAmount = pivotedData.reduce(
        (sum, row) => sum + row[category][0],
        0
      );
      const totalCustomers = pivotedData.reduce(
        (sum, row) => sum + row[category][1],
        0
      );
      totalRow[category] = [totalAmount, totalCustomers];
    });
    pivotedData.push(totalRow);
    console.log("Pivoted data->", JSON.stringify(pivotedData, null, 2));
    return pivotedData;
  };
  let segmentLabels = [];
  let data = [];

  if (selectedOption.id === 1) {
    const segmentData = getTotalForAmountReceivableRiskScore(summary, agingDays);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  } else if (selectedOption.id === 2) {
    const segmentData = getTotalForAmountReceivablePaydex(summary, agingDays);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  } else {
    const segmentData = getTotalForAmountReceivableRiskSegment(summary, agingDays);
    segmentLabels = getSegmentLabels(selectedOption.id);
    data = constructData(segmentData, selectedOption.label);
  }

  console.log("Final data-> ", JSON.stringify(data, null, 2));

  const handleOnClick = (segmentIndex, category) => {
    if (data[segmentIndex].segment === "Total") return;
    const segmentLabels = getSegmentLabels(selectedOption.id);
    const segment = segmentLabels[segmentIndex];
    let filteredSummary = [];
    let segmentData;
    if (selectedOption.id === 3) {
      segmentData = getRiskyCustomerRiskSegment(summary, segmentIndex + 1);
    } else if (selectedOption.id === 2) {
      segmentData = getRiskyCustomerPaydex(summary, segmentIndex + 1);
    } else {
      segmentData = getRiskyCustomerRiskScore(summary, segmentIndex + 1);
    }
  
    const isAgingCategory = Object.values(agingDays).some((ageLabel) => category === `AR Balance Aging ${ageLabel}`);
  
    let agingDayKey = null;
    if (isAgingCategory) {
      for (const key in agingDays) {
        if (category === `AR Balance Aging ${agingDays[key]}`) {
          agingDayKey = agingDays[key];
          break;
        }
      }
    }
  
    segmentData.forEach((item) => {
      const processedAgingData = processAgingData(item, agingDays);
  
      if (isAgingCategory && agingDayKey) {
        const agingValue = processedAgingData[agingDayKey];
        if (agingValue) {
          filteredSummary.push(item);
        }
      } else {
        if (item[category]) {
          filteredSummary.push(item);
        }
      }
    });

    localStorage.setItem("segmentName", JSON.stringify(selectedOption.label));
    localStorage.setItem("rangeTypeName", JSON.stringify(segment));
    localStorage.setItem("metricName", JSON.stringify(category));
    localStorage.setItem("filteredSummary", JSON.stringify(filteredSummary));

    const selectedTenant = getTenantIdFromURL();
    if (adminAction === true)
      window.open(
        `/tenant-dashboard/${selectedTenant}/filteredSummary`,
        "_blank"
      );
    else window.open("/filteredSummary", "_blank");
  };

  const cardStyle = {
    background: "#FAFAFC 0% 0% no-repeat padding-box",
    boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
    borderRadius: "8px",
    padding: "20px 20px 0 20px",
    position: "relative",
    marginTop: "15px",
  };

  return (
    <Card sx={cardStyle}>
      <Typography variant="h5" sx={{ fontWeight: 500 }} mb={2}>
        AR Heat Map
      </Typography>
      <Autocomplete
        size="small"
        sx={{ width: 250, my: 5 }}
        value={selectedOption}
        onChange={handleDropdownChange}
        options={xAxisOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField {...params} label="Segmentation Type" variant="outlined" />
        )}
      />
      <Box sx={{ width: "100%", overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Segment</TableCell>
              {agingCategories.map((category) => (
                <TableCell key={category}>{category}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.segment}>
                <TableCell>{row.segment}</TableCell>
                {agingCategories.map((category) => (
                  <TableCell
                    key={category}
                    onClick={() =>
                      row.segment !== "Total" &&
                      row[category] &&
                      row[category][0]
                        ? handleOnClick(index, category)
                        : null
                    }
                    sx={{
                      cursor:
                        row.segment !== "Total" &&
                        row[category] &&
                        row[category][0]
                          ? "pointer"
                          : "default",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px" }}>
                      <img src={dollar} style={{ display: "inline" }}></img>
                      {row[category] && row[category][0]
                        ? row[category][0].toLocaleString()
                        : 0}
                    </Typography>
                    {
                      <Typography sx={{ fontSize: "12px" }} variant="caption">
                        <img
                          src={company}
                          style={{ display: "inline", marginRight: "1px" }}
                        ></img>
                        {row[category] && row[category][1]
                          ? row[category][1]
                          : 0}
                      </Typography>
                    }
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default ARHeatmapNew;
