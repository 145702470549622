import { API, graphqlOperation } from "aws-amplify";
import { createAlert, updateAlert } from "../../graphql/mutations";
import { getTenantIdAuth0, isAdminAuth0 } from "../../authUtils";
import { getDashboardPreferences, listAlerts } from "../../graphql/queries";
import getTenantIdFromURL from "../getTenantIdFromURL";

export const CreateAlertItem = async (message) => {
  try {
  console.log("createalerts")

    const isAdmin= isAdminAuth0();
    const urltenant = getTenantIdFromURL();
    const currentTenant = getTenantIdAuth0();
    const tenantId = isAdmin?urltenant:currentTenant;
    const result = await API.graphql(
      graphqlOperation(createAlert, {
        input: {
          tenantId: tenantId,
          message: message,
          isRead: false,
        },
      })
    );
    console.log("created item in preferences!");
  } catch (error) {
    console.error("Error creating preferences:", error);
  }
};

export const getUnreadAlerts = async () => {
  let allUnreadAlerts = [];
  let nextToken = null;
  const isAdmin = await isAdminAuth0();
  const urltenant = getTenantIdFromURL();
  const currentTenant = await  getTenantIdAuth0();
  const tenantId = isAdmin ? urltenant : currentTenant;
  try {
        // Fetch user's notification preferences
        const preferencesResponse = await API.graphql(
          graphqlOperation(getDashboardPreferences, {
            tenantId: tenantId,
            feature: "Notification Preferences",
          })
        );
    
        let preferences = {
          Complete: true,
          Update: true,
          "Status Change": true
        };
    
        if (preferencesResponse.data.getDashboardPreferences) {
          preferences = JSON.parse(preferencesResponse.data.getDashboardPreferences.preferences);
        }

    do {
      const response = await API.graphql(
        graphqlOperation(listAlerts, {
          tenantId: tenantId,
          // filter: {
          //   isRead: { eq: false },
          // },
          nextToken: nextToken,
        })
      );

      const unreadAlerts = response.data.listAlerts.items;
      allUnreadAlerts = allUnreadAlerts.concat(unreadAlerts);
      nextToken = response.data.listAlerts.nextToken;
    } while (nextToken);

    const filteredAlerts = allUnreadAlerts.filter(alert => {

      const alertType = alert.type;
    
      if (alertType === "Update" && preferences.Update) return true;
      if (alertType === "Status Change" && preferences["Status Change"]) return true;
      if ((!alertType || !["Update", "Status Change"].includes(alertType)) && preferences.Complete) {
        return true;
      }
      return false;
    }); 

    filteredAlerts.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    const topAlerts = filteredAlerts.slice(0,50)
    console.log("all Alerts", allUnreadAlerts)
    console.log("filtered Alerts:", filteredAlerts);
    console.log("Top 50 Alerts:", topAlerts);
    return topAlerts;
  } catch (error) {
    console.error("Error fetching unread alerts:", error);
    return [];
  }
};

export const bulkUpdateAlerts = async (alertsToUpdate) => {
  const isReadValue = true;
  try {
    const updatePromises = alertsToUpdate.map((alert) =>
      API.graphql(
        graphqlOperation(updateAlert, {
          input: {
            tenantId: alert.tenantId,
            id: alert.id,
            isRead: isReadValue,
          },
        })
      )
    );

    await Promise.all(updatePromises);
    console.log("Bulk update complete.");
    return true;
  } catch (error) {
    console.error("Error updating alerts:", error);
    return false;
  }
};
