import React, { useState } from "react";

const TimelineItem = ({ data }) => (
  <div className="flex">
    <div className="flex flex-col items-center mr-4">
      <div className="w-4 rounded-full"></div>
      <div className="w-1 h-full bg-[#5186EC]"></div>
    </div>
    <div className="flex mb-8">
      <div className="flex items-center gap-5">
        <div>
          <p className="text-sm text-gray-500">
            {data?.date
              ? new Date(data.date).toLocaleDateString()
              : "Date not available"}
          </p>
        </div>
        <div className="flex-1">
          <p
            className="text-lg font"
            style={{
              textAlign: "left",
              textDecoration: "underline",
              fontFamily: "Rubik , sans-serif",
              letterSpacing: "0px",
              opacity: 1,
              color: "#5186EC",
              fontSize: "16px",
              padding: "5px",
              display: "block",
              wordWrap: "break-word",
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {data?.url ? (
              <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {data?.title || "Untitled Event"}
              </a>
            ) : (
              data?.title || "Untitled Event"
            )}
          </p>
          <p className="text-[#777A82]" style={{
              textAlign: "left",
              fontFamily: "Rubik, sans-serif",
              letterSpacing: "0px",
              padding: "5px",
              color: "#777A82"
            }}>
            {data?.description || "No description available"}
          </p>
        </div>
      </div>
    </div>
  </div>
);

const CompanyEventTimeline = ({ timelineData }) => {
    const [visibleEvents, setVisibleEvents] = useState(4);

    const showMoreEvents = () => {
        setVisibleEvents((prevVisible) => prevVisible + 8);
    };

    if (!timelineData || timelineData.length === 0) {
        return (
            <div className="container mx-auto px-4 py-8">
                <h2 className="text-2xl font-bold mb-6">
                    Company Event Timeline
                </h2>
                <p className="text-center text-gray-600">
                    No events to display at this time.
                </p>
            </div>
        );
    }

    return (
        <div className="container mx-auto px-6 py-8">
            <h2 className=" text-[#1A2A56] mb-6" style={{fontSize:"20px",fontWeight:"500"}}>Company Event Timeline</h2>
            {timelineData.slice(0, visibleEvents).map((data, idx) => (
                <TimelineItem key={idx} data={data} />
            ))}
            {visibleEvents < timelineData.length && (
                <div className="text-center mt-4">
                    <button
                        onClick={showMoreEvents}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        See More
                    </button>
                </div>
            )}
        </div>
    );
};

export default CompanyEventTimeline;
