import { Box, Typography } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";

const xlsx = require("xlsx");

function identifyFileByName(fileName) {
  const lowerCaseName = fileName.toLowerCase();

  const historicalKeywords = ["history", "payment", "invoice", "main"];
  const isHistorical = historicalKeywords.some((keyword) =>
    lowerCaseName.includes(keyword)
  );

  const agingKeywords = ["aging"];
  const isAging = agingKeywords.some((keyword) =>
    lowerCaseName.includes(keyword)
  );

  if (isHistorical) {
    return "Historical Payment and Invoices";
  } else if (isAging) {
    return "Aging Summary";
  }

  return "Unknown";
}

function identifyFileByContent(headers) {
  const agingKeywords = ["current", "aging", "open balance", "total debt"];
  const isAgingFile = agingKeywords.some((keyword) =>
    headers.some((h) => h.includes(keyword))
  );

  const historicalKeywords = [
    "invoice",
    "payment",
    "amount",
    "document number",
    "date due",
    "payment date",
    "vat",
  ];
  const isHistoricalFile = historicalKeywords.some((keyword) =>
    headers.some((h) => h.includes(keyword))
  );

  if (isAgingFile) {
    return "Aging Summary";
  } else if (isHistoricalFile) {
    return "Historical Payment and Invoices";
  }

  return "Unknown";
}

export function identifyFile(file) {
  return new Promise(async (resolve, reject) => {
    if (!(file instanceof File)) {
      console.error("Provided input is not a valid file.");
      reject("Invalid file");
    }

    try {
      const headers = await parseCSVColumns(file);

      const processedHeaders = headers.map((header) =>
        typeof header === "string" ? header.toLowerCase() : header
      );

      let fileType = identifyFileByName(file.name);

      if (fileType === "Unknown") {
        console.log("headers", processedHeaders);
        fileType = identifyFileByContent(processedHeaders);
      }

      console.log("File Classification:", file.name, fileType);
      resolve(fileType);
    } catch (error) {
      console.error("Error processing the file:", error);
      reject(error);
    }
  });
}

export const parseCSVColumns = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      const sheetData = await readFileData(file);
      const headers = sheetData[0];
      resolve(headers);
    } catch (error) {
      console.error("Error parsing the CSV columns:", error);
      reject(error);
    }
  });
};

export const validateColumnMapping = (mapping, requiredFields) => {
  return requiredFields.every((field) => mapping[field]);
};

export const mapToPredefinedStructure = (row, columnMapping, date) => {
  const mappedData = {};

  Object.keys(columnMapping).forEach((key) => {
    const columnName = columnMapping[key];
    const value = columnName ? row[columnName] : undefined;
    mappedData[key] = value !== undefined ? value : "";
  });
  if (date) mappedData["updatedUpTo"] = date; 

  return mappedData;
};

export const mergeFiles = async (files, fileTypes, columnMappings, dateSelections) => {
  const agingData = [];
  const paymentData = [];

  for (const file of files) {
    const fileType = fileTypes[file.name];
    const columnMapping = columnMappings[file.name];
    const date = dateSelections[file.name]; 

    const fileData = await readFileData(file);
    const resultJSON = convertToJSON(fileData);

    console.log("resultJSON", resultJSON);
    if (!fileData || !Array.isArray(fileData) || fileData.length === 0) {
      console.error(
        `File ${file.name} is not properly formatted or has no data.`
      );
      continue;
    }

    const cleanedData = resultJSON.map((item) => {
      const cleanedItem = {};
      Object.keys(item).forEach((key) => {
        const trimmedKey = key.trim();
        const value = item[key] ? item[key].toString().trim() : ""; 
        cleanedItem[trimmedKey] = value;
      });
      return cleanedItem;
    });

    cleanedData.forEach((row) => {
      if (fileType === "Aging Summary") {
        agingData.push(mapToPredefinedStructure(row, columnMapping, date));
      } else if (fileType === "Historical Payment and Invoices") {
        paymentData.push(mapToPredefinedStructure(row, columnMapping));
      }
    });
  }

  const filteredAgingData = agingData.filter((item) =>
    Object.keys(item).some(
      (key) => key !== "updatedUpTo" && item[key] !== "" && item[key] !== 0
    )
  );

  const filteredPaymentData = paymentData.filter((item) =>
    Object.values(item).some((value) => value !== "" && value !== 0)
  );

  return { agingData: filteredAgingData, paymentData: filteredPaymentData };
};

const convertToJSON = (data) => {
  const headers = data[0];
  const jsonData = [];

  for (let i = 1; i < data.length; i++) {
    const row = data[i];
    const jsonRow = {};

    for (let j = 0; j < headers.length; j++) {
      jsonRow[headers[j]] = row[j];
    }

    jsonData.push(jsonRow);
  }

  return jsonData;
};

const readFileData = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const jsonData = xlsx.utils.sheet_to_json(sheet, {
          header: 1,
          raw: false,
          defval: "",
        });
        resolve(jsonData);
      } catch (error) {
        console.error("Error reading the file data:", error);
        reject(error);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

export const AgingSummaryColumnMapper = ({
  file,
  columnMappings,
  setColumnMappings,
}) => {
  const [columnMapping, setColumnMapping] = useState({
    name: "",
    accountID: "",
    address: "",
    current: "",
    aging15: "",
    aging30: "",
    aging45: "",
    aging60: "",
    aging90: "",
    aging120:"",
    aging180: "",
    aging365: "",
    aging60Plus: "",
    aging90Plus: "",
    aging120Plus:"",
    aging180Plus: "",
    aging365Plus: "",
    age: "",
    collectionAtRisk: "",
    transactionType: "",
    reason: "",
    marketSegment: "",
    country: "",
    city: "",
    zipCode: "",
    state: "",
    totalDebt: "",
    AvgCustomerPaymentDays: "",
  });

  const [availableColumns, setAvailableColumns] = useState([]);

  useEffect(() => {
    const readFileColumns = async () => {
      const columns = await parseCSVColumns(file);
      const trimmedColumns = columns.map((col) => col.trim());
      setAvailableColumns(trimmedColumns);
      autoFillColumnMapping(trimmedColumns);
    };

    readFileColumns();
  }, [file]);

  const agingSummaryKeywords = {
    name: ["customer name", "name", "company", "client"],
    accountID: ["account", "customer#", "accountid"],
    address: ["address", "location", "site", "billing address"],
    current: [
      "current",
      "open balance",
      "current balance",
      "outstanding balance",
    ],
    aging15: ["15", "aging 15", "open balance 15", "15 days", "15 days aging"],
    aging30: ["30", "aging 30", "open balance 30", "30 days", "30 days aging"],
    aging45: ["45", "aging 45", "open balance 45", "45 days", "45 days aging"],
    aging60: ["60", "aging 60", "open balance 60", "60 days", "60 days aging"],
    aging90: ["90", "aging 90", "open balance 90", "90 days", "90 days aging"],
    aging120: [
      "120",
      "aging 120",
      "open balance 120",
      "120 days",
      "120 days aging",
    ],
    aging180: [
      "180",
      "aging 180",
      "open balance 180",
      "180 days",
      "180 days aging",
    ],
    aging365: [
      "365",
      "aging 365",
      "open balance 365",
      "365 days",
      "365 days aging",
    ],
    aging60Plus: ["60+", ">60", "aging 60+", "over 60", "greater than 60"],
    aging90Plus: ["90+", ">90", "aging 90+", "over 90", "greater than 90"],
    aging120Plus: [
      "120+",
      ">120",
      "aging 120+",
      "over 120",
      "greater than 120",
    ],
    aging180Plus: [
      "180+",
      ">180",
      "aging 180+",
      "over 180",
      "greater than 180",
    ],
    aging365Plus: [
      "365+",
      ">365",
      "aging 365+",
      "over 365",
      "greater than 365",
    ],
    age: ["age", "current age"],
    collectionAtRisk: ["Collection at Risk", "Risk"],
    transactionType: ["Transaction Type", "Type"],
    reason: ["reason", "notes", "explanation", "justification"],
    marketSegment: ["market segment", "segment"],
    country: ["country"],
    city: ["city"],
    zipCode: ["zip code", "postal code", "postcode", "zip"],
    state: ["state", "address state"],
    totalDebt: [
      "total",
      "amount due",
      "invoice total",
      "total amount",
      "amount",
    ],
    AvgCustomerPaymentDays: [
      "Entity: avg Customer payment Days",
      "average",
      "payment days",
    ],
  };

  const autoFillColumnMapping = (columns) => {
    const newMapping = { ...columnMapping };

    Object.entries(agingSummaryKeywords).forEach(([key, keywords]) => {
      const matchedColumn = columns.find((col) => {
        if (key === "age") {
          return keywords.some(
            (keyword) => col.toLowerCase().trim() === keyword.toLowerCase()
          );
        }
        return keywords.some((keyword) =>
          col.toLowerCase().includes(keyword.toLowerCase())
        );
      });

      if (matchedColumn) {
        newMapping[key] = matchedColumn;
      }
    });

    setColumnMapping(newMapping);
  };

  useEffect(() => {
    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [file.name]: columnMapping,
    }));
  }, [columnMapping, file.name, setColumnMappings]);

  return (
    <Box>
      {Object.entries(columnMapping).map(([key, value]) => (
        <FormControl fullWidth sx={{ mb: 2 }} key={key}>
          <InputLabel>
            {key.charAt(0).toUpperCase() +
              key.slice(1).replace(/([A-Z])/g, " $1")}
          </InputLabel>

          <Select
            value={value}
            label={key.charAt(0).toUpperCase() + key.slice(1)}
            onChange={(e) =>
              setColumnMapping((prev) => ({
                ...prev,
                [key]: e.target.value,
              }))
            }>
            {availableColumns.map((col) => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
    </Box>
  );
};

export const HistoricalPaymentColumnMapper = ({ file, setColumnMappings }) => {
  const [columnMapping, setColumnMapping] = useState({
    account: "",
    customer: "",
    billingName: "",
    vatRegistration: "",
    country: "",
    city: "",
    state: "",
    address: "",
    zipCode: "",
    email: "",
    emailFirstName: "",
    phone: "",
    marketSegment: "",
    invoiceNumber: "",
    invoiceDate: "",
    invoiceAmount: "",
    dueDate: "",
    payment: "",
    paymentDate: "",
    paymentPeriod: "",
    currency: "",
    amountPayment: "",
    amountUSD: "",
    amountDue: "",
    terms: "",
  });

  const [availableColumns, setAvailableColumns] = useState([]);

  const historicalPaymentKeywords = {
    account: ["account", "account id", "account number"],
    customer: ["customer id", "customer name", "customer", "client"],
    billingName: ["billing name", "bill to", "invoice name"],
    vatRegistration: [
      "vat number",
      "vat registration number",
      "vat reg. no",
      "vat",
    ],
    country: ["country"],
    city: ["city"],
    state: ["state", "address state"],
    address: ["address", "location", "site", "billing address"],
    zipCode: ["zip code", "postal code", "postcode", "zip"],
    email: ["email", "email address"],
    emailFirstName: ["email first name", "first name"],
    phone: ["phone", "telephone", "contact number"],
    marketSegment: ["market segment", "segment"],
    invoiceNumber: [
      "Bill",
      "Invoice",
      "bill number",
      "invoice number",
      "document number",
      "document",
    ],
    invoiceDate: [
      "Bill\\Invoice Date",
      "bill date",
      "invoice date",
      "document date",
      "date",
    ],
    invoiceAmount: [
      "amount",
      "amount (foreign currency)",
      "invoice amount",
      "total amount",
    ],
    dueDate: ["due date", "payment due date"],
    payment: ["payment", "payment status"],
    paymentDate: ["payment date", "date paid"],
    paymentPeriod: ["payment period", "billing period"],
    currency: ["currency", "invoice currency"],
    amountPayment: ["amount payment", "payment amount"],
    amountUSD: [
      "amount in usd",
      "amount (usd)",
      "amount in foreign currency",
      "usd equivalent",
    ],
    amountDue: ["amount due", "remaining balance", "balance due"],
    terms: ["Terms", "Net terms"],
  };

  useEffect(() => {
    const readFileColumns = async () => {
      const columns = await parseCSVColumns(file);
      const trimmedColumns = columns.map((col) => col.trim());
      setAvailableColumns(trimmedColumns);
      autoFillColumnMapping(trimmedColumns);
    };

    readFileColumns();
  }, [file]);

  const autoFillColumnMapping = (columns) => {
    const newMapping = { ...columnMapping };

    Object.entries(historicalPaymentKeywords).forEach(([key, keywords]) => {
      const matchedColumn = columns.find((col) =>
        keywords.some((keyword) =>
          col.toLowerCase().includes(keyword.toLowerCase())
        )
      );
      if (matchedColumn) {
        newMapping[key] = matchedColumn;
      }
    });

    setColumnMapping(newMapping);
  };

  useEffect(() => {
    setColumnMappings((prevMappings) => ({
      ...prevMappings,
      [file.name]: columnMapping,
    }));
  }, [columnMapping, file.name, setColumnMappings]);

  return (
    <Box>
      {Object.entries(columnMapping).map(([key, value]) => (
        <FormControl fullWidth sx={{ mb: 2 }} key={key}>
          <InputLabel>
            {key.charAt(0).toUpperCase() +
              key.slice(1).replace(/([A-Z])/g, " $1")}
          </InputLabel>
          <Select
            value={value}
            label={
              key.charAt(0).toUpperCase() +
              key.slice(1).replace(/([A-Z])/g, " $1")
            }
            onChange={(e) =>
              setColumnMapping((prev) => ({
                ...prev,
                [key]: e.target.value,
              }))
            }>
            {availableColumns.map((col) => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ))}
      <Typography variant='caption' sx={{ mt: 2 }}>
        Select the corresponding columns from your file
      </Typography>
    </Box>
  );
};
