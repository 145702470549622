function formatNumber(value) {
  return isNaN(value) || !isFinite(value)
    ? "0"
    : Math.round(value).toLocaleString();
}

export const columns = [
  {
    field: "totalAR",
    headerName: "Total Open Balance",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.totalAR}</div>;
    },
  },
  {
    field: "currentAR",
    headerName: "Open Balance Current",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.currentAR}</div>;
    },
  },
  {
    field: "aging30AR",
    headerName: "Open Balance Age 30",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.aging30AR}</div>;
    },
  },
  {
    field: "aging60AR",
    headerName: "Open Balance Age 60",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.aging60AR}</div>;
    },
  },
  {
    field: "aging90AR",
    headerName: "Open Balance Age 90",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.aging90AR}</div>;
    },
  },
  {
    field: "aging180AR",
    headerName: "Open Balance Age 180",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.aging180AR}</div>;
    },
  },
  {
    field: "aging365AR",
    headerName: "Open Balance Age 365",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return <div style={{ cursor: "pointer" }}>{params.row.aging365AR}</div>;
    },
  },
  {
    field: "aging365PlusAR",
    headerName: "Open Balance Age 365+",
    width: 200,
    sortable: false,
    renderCell: function (params) {
      return (
        <div style={{ cursor: "pointer" }}>{params.row.aging365PlusAR}</div>
      );
    },
  },
];

export const getAggregatedDataForCurrentNet = (data, segment) => {
  let totals = {
    totalARBalance: 0,
    customerWithTotalAR: 0,
    currentARBalance: 0,
    customerWithCurrentAR: 0,
    aging30: 0,
    customerWithAging30: 0,
    aging60: 0,
    customerWithAging60: 0,
    aging90: 0,
    customerWithAging90: 0,
    aging180: 0,
    customerWithAging180: 0,
    aging365: 0,
    customerWithAging365: 0,
    aging365Plus: 0,
    customerWithAging365Plus: 0,

    totalCN30: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalCN45: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalCN60: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalCN90: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalCN120: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalCN180: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
    totalOther: {
      totalARBalance: 0,
      count: 0,
      currentARBalance: 0,
      customerWithCurrentAR: 0,
      aging30: 0,
      customerWithAging30: 0,
      aging60: 0,
      customerWithAging60: 0,
      aging90: 0,
      customerWithAging90: 0,
      aging180: 0,
      customerWithAging180: 0,
      aging365: 0,
      customerWithAging365: 0,
      aging365Plus: 0,
      customerWithAging365Plus: 0,
    },
  };

  data.forEach((item) => {
    if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
      totals.totalARBalance += parseFloat(item["Total AR Balance"]);
      totals.customerWithTotalAR += 1;
    }

    if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
      totals.currentARBalance += parseFloat(item["Current AR Balance"]);
      totals.customerWithCurrentAR += 1;
    }

    if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
      totals.aging30 += parseFloat(item["AR Balance Aging 30"]);
      totals.customerWithAging30 += 1;
    }

    if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
      totals.aging60 += parseFloat(item["AR Balance Aging 60"]);
      totals.customerWithAging60 += 1;
    }

    if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
      totals.aging90 += parseFloat(item["AR Balance Aging 90"]);
      totals.customerWithAging90 += 1;
    }

    if (item["AR Balance Aging 180"] > 0 || item["AR Balance Aging 180"] < 0) {
      totals.aging180 += parseFloat(item["AR Balance Aging 180"]);
      totals.customerWithAging180 += 1;
    }

    if (item["AR Balance Aging 365"] > 0 || item["AR Balance Aging 365"] < 0) {
      totals.aging365 += parseFloat(item["AR Balance Aging 365"]);
      totals.customerWithAging365 += 1;
    }

    if (
      item["AR Balance Aging 365+"] > 0 ||
      item["AR Balance Aging 365+"] < 0
    ) {
      totals.aging365Plus += parseFloat(item["AR Balance Aging 365+"]);
      totals.customerWithAging365Plus += 1;
    }

    if (item[segment] === "Net 30") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN30.totalARBalance += parseFloat(item["Total AR Balance"]);
        totals.totalCN30.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN30.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN30.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN30.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN30.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN30.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN30.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN30.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN30.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN30.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN30.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN30.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN30.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN30.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN30.customerWithAging365Plus += 1;
      }
    } else if (item[segment] === "Net 45") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN45.totalARBalance += parseFloat(item["Total AR Balance"]);
        totals.totalCN45.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN45.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN45.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN45.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN45.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN45.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN45.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN45.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN45.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN45.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN45.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN45.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN45.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN45.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN45.customerWithAging365Plus += 1;
      }
    } else if (item[segment] === "Net 60") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN60.totalARBalance += parseFloat(item["Total AR Balance"]);
        totals.totalCN60.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN60.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN60.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN60.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN60.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN60.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN60.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN60.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN60.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN60.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN60.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN60.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN60.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN60.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN60.customerWithAging365Plus += 1;
      }
    } else if (item[segment] === "Net 90") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN90.totalARBalance += parseFloat(item["Total AR Balance"]);
        totals.totalCN90.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN90.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN90.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN90.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN90.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN90.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN90.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN90.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN90.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN90.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN90.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN90.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN90.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN90.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN90.customerWithAging365Plus += 1;
      }
    } else if (item[segment] === "Net 120") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN120.totalARBalance += parseFloat(
          item["Total AR Balance"]
        );
        totals.totalCN120.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN120.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN120.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN120.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN120.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN120.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN120.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN120.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN120.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN120.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN120.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN120.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN120.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN120.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN120.customerWithAging365Plus += 1;
      }
    } else if (item[segment] === "Net 180") {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalCN180.totalARBalance += parseFloat(
          item["Total AR Balance"]
        );
        totals.totalCN180.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalCN180.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalCN180.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalCN180.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalCN180.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalCN180.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalCN180.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalCN180.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalCN180.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalCN180.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalCN180.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalCN180.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalCN180.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalCN180.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalCN180.customerWithAging365Plus += 1;
      }
    } else {
      if (item["Total AR Balance"] > 0 || item["Total AR Balance"] < 0) {
        totals.totalOther.totalARBalance += parseFloat(
          item["Total AR Balance"]
        );
        totals.totalOther.count += 1;
      }

      if (item["Current AR Balance"] > 0 || item["Current AR Balance"] < 0) {
        totals.totalOther.currentARBalance += parseFloat(
          item["Current AR Balance"]
        );
        totals.totalOther.customerWithCurrentAR += 1;
      }

      if (item["AR Balance Aging 30"] > 0 || item["AR Balance Aging 30"] < 0) {
        totals.totalOther.aging30 += parseFloat(item["AR Balance Aging 30"]);
        totals.totalOther.customerWithAging30 += 1;
      }

      if (item["AR Balance Aging 60"] > 0 || item["AR Balance Aging 60"] < 0) {
        totals.totalOther.aging60 += parseFloat(item["AR Balance Aging 60"]);
        totals.totalOther.customerWithAging60 += 1;
      }

      if (item["AR Balance Aging 90"] > 0 || item["AR Balance Aging 90"] < 0) {
        totals.totalOther.aging90 += parseFloat(item["AR Balance Aging 90"]);
        totals.totalOther.customerWithAging90 += 1;
      }

      if (
        item["AR Balance Aging 180"] > 0 ||
        item["AR Balance Aging 180"] < 0
      ) {
        totals.totalOther.aging180 += parseFloat(item["AR Balance Aging 180"]);
        totals.totalOther.customerWithAging180 += 1;
      }

      if (
        item["AR Balance Aging 365"] > 0 ||
        item["AR Balance Aging 365"] < 0
      ) {
        totals.totalOther.aging365 += parseFloat(item["AR Balance Aging 365"]);
        totals.totalOther.customerWithAging365 += 1;
      }

      if (
        item["AR Balance Aging 365+"] > 0 ||
        item["AR Balance Aging 365+"] < 0
      ) {
        totals.totalOther.aging365Plus += parseFloat(
          item["AR Balance Aging 365+"]
        );
        totals.totalOther.customerWithAging365Plus += 1;
      }
    }
  });

  const rowDataAR = [
    {
      id: 1,
      metric: "Total AR Balance",
      customer: formatNumber(Math.round(totals.customerWithTotalAR)),
      total: formatNumber(Math.round(totals.totalARBalance)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.totalARBalance)),
      customerCN30: formatNumber(Math.round(totals.totalCN30.count)),
      totalCN45: formatNumber(Math.round(totals.totalCN45.totalARBalance)),
      customerCN45: formatNumber(Math.round(totals.totalCN45.count)),
      totalCN60: formatNumber(Math.round(totals.totalCN60.totalARBalance)),
      customerCN60: formatNumber(Math.round(totals.totalCN60.count)),
      totalCN90: formatNumber(Math.round(totals.totalCN90.totalARBalance)),
      customerCN90: formatNumber(Math.round(totals.totalCN90.count)),
      totalCN120: formatNumber(Math.round(totals.totalCN120.totalARBalance)),
      customerCN120: formatNumber(Math.round(totals.totalCN120.count)),
      totalCN180: formatNumber(Math.round(totals.totalCN180.totalARBalance)),
      customerCN180: formatNumber(Math.round(totals.totalCN180.count)),
      totalOther: formatNumber(Math.round(totals.totalOther.totalARBalance)),
      customerOther: formatNumber(Math.round(totals.totalOther.count)),
    },
    {
      id: 2,
      metric: "Current AR Balance",
      customer: formatNumber(Math.round(totals.customerWithCurrentAR)),
      total: formatNumber(Math.round(totals.currentARBalance)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.currentARBalance)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithCurrentAR)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.currentARBalance)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithCurrentAR)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.currentARBalance)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithCurrentAR)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.currentARBalance)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithCurrentAR)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.currentARBalance)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithCurrentAR)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.currentARBalance)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithCurrentAR)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.currentARBalance)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithCurrentAR)
      ),
    },
    {
      id: 3,
      metric: "AR Balance Aging 30",
      customer: formatNumber(Math.round(totals.customerWithAging30)),
      total: formatNumber(Math.round(totals.aging30)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging30)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging30)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging30)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging30)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging30)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging30)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging30)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging30)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging30)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging30)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging30)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging30)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging30)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging30)
      ),
    },
    {
      id: 4,
      metric: "AR Balance Aging 60",
      customer: formatNumber(Math.round(totals.customerWithAging60)),
      total: formatNumber(Math.round(totals.aging60)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging60)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging60)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging60)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging60)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging60)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging60)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging60)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging60)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging60)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging60)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging60)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging60)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging60)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging60)
      ),
    },
    {
      id: 5,
      metric: "AR Balance Aging 90",
      customer: formatNumber(Math.round(totals.customerWithAging90)),
      total: formatNumber(Math.round(totals.aging90)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging90)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging90)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging90)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging90)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging90)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging90)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging90)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging90)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging90)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging90)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging90)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging90)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging90)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging90)
      ),
    },
    {
      id: 6,
      metric: "AR Balance Aging 180",
      customer: formatNumber(Math.round(totals.customerWithAging180)),
      total: formatNumber(Math.round(totals.aging180)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging180)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging180)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging180)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging180)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging180)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging180)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging180)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging180)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging180)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging180)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging180)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging180)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging180)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging180)
      ),
    },
    {
      id: 7,
      metric: "AR Balance Aging 365",
      customer: formatNumber(Math.round(totals.customerWithAging365)),
      total: formatNumber(Math.round(totals.aging365)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging365)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging365)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging365)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging365)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging365)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging365)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging365)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging365)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging365)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging365)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging365)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging365)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging365)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging365)
      ),
    },
    {
      id: 8,
      metric: "AR Balance Aging 365+",
      customer: formatNumber(Math.round(totals.customerWithAging365Plus)),
      total: formatNumber(Math.round(totals.aging365Plus)),
      totalCN30: formatNumber(Math.round(totals.totalCN30.aging365Plus)),
      customerCN30: formatNumber(
        Math.round(totals.totalCN30.customerWithAging365Plus)
      ),
      totalCN45: formatNumber(Math.round(totals.totalCN45.aging365Plus)),
      customerCN45: formatNumber(
        Math.round(totals.totalCN45.customerWithAging365Plus)
      ),
      totalCN60: formatNumber(Math.round(totals.totalCN60.aging365Plus)),
      customerCN60: formatNumber(
        Math.round(totals.totalCN60.customerWithAging365Plus)
      ),
      totalCN90: formatNumber(Math.round(totals.totalCN90.aging365Plus)),
      customerCN90: formatNumber(
        Math.round(totals.totalCN90.customerWithAging365Plus)
      ),
      totalCN120: formatNumber(Math.round(totals.totalCN120.aging365Plus)),
      customerCN120: formatNumber(
        Math.round(totals.totalCN120.customerWithAging365Plus)
      ),
      totalCN180: formatNumber(Math.round(totals.totalCN180.aging365Plus)),
      customerCN180: formatNumber(
        Math.round(totals.totalCN180.customerWithAging365Plus)
      ),
      totalOther: formatNumber(Math.round(totals.totalOther.aging365Plus)),
      customerOther: formatNumber(
        Math.round(totals.totalOther.customerWithAging365Plus)
      ),
    },
  ];

  return rowDataAR;
};

export const getCollectionTableRows = (data) => {
  const CNData = getAggregatedDataForCurrentNet(data, "Current Net");

  const rows = [
    { path: "Net 30", id: 0 },
    {
      id: 1,
      path: "Net 30/Total Sum",
      totalAR: CNData[0].totalCN30,
      currentAR: CNData[1].totalCN30,
      aging30AR: CNData[2].totalCN30,
      aging60AR: CNData[3].totalCN30,
      aging90AR: CNData[4].totalCN30,
      aging180AR: CNData[5].totalCN30,
      aging365AR: CNData[6].totalCN30,
      aging365PlusAR: CNData[7].totalCN30,
      rangeId: 1,
    },
    {
      id: 2,
      path: "Net 30/Number of customer",
      totalAR: CNData[0].customerCN30,
      currentAR: CNData[1].customerCN30,
      aging30AR: CNData[2].customerCN30,
      aging60AR: CNData[3].customerCN30,
      aging90AR: CNData[4].customerCN30,
      aging180AR: CNData[5].customerCN30,
      aging365AR: CNData[6].customerCN30,
      aging365PlusAR: CNData[7].customerCN30,
      rangeId: 1,
    },

    { path: "Net 45", id: 3 },
    {
      id: 4,
      path: "Net 45/Total Sum",
      totalAR: CNData[0].totalCN45,
      currentAR: CNData[1].totalCN45,
      aging30AR: CNData[2].totalCN45,
      aging60AR: CNData[3].totalCN45,
      aging90AR: CNData[4].totalCN45,
      aging180AR: CNData[5].totalCN45,
      aging365AR: CNData[6].totalCN45,
      aging365PlusAR: CNData[7].totalCN45,
      rangeId: 2,
    },
    {
      id: 5,
      path: "Net 45/Number of customer",
      totalAR: CNData[0].customerCN45,
      currentAR: CNData[1].customerCN45,
      aging30AR: CNData[2].customerCN45,
      aging60AR: CNData[3].customerCN45,
      aging90AR: CNData[4].customerCN45,
      aging180AR: CNData[5].customerCN45,
      aging365AR: CNData[6].customerCN45,
      aging365PlusAR: CNData[7].customerCN45,
      rangeId: 2,
    },

    { path: "Net 60", id: 6 },
    {
      id: 7,
      path: "Net 60/Total Sum",
      totalAR: CNData[0].totalCN60,
      currentAR: CNData[1].totalCN60,
      aging30AR: CNData[2].totalCN60,
      aging60AR: CNData[3].totalCN60,
      aging90AR: CNData[4].totalCN60,
      aging180AR: CNData[5].totalCN60,
      aging365AR: CNData[6].totalCN60,
      aging365PlusAR: CNData[7].totalCN60,
      rangeId: 3,
    },
    {
      id: 8,
      path: "Net 60/Number of customer",
      totalAR: CNData[0].customerCN60,
      currentAR: CNData[1].customerCN60,
      aging30AR: CNData[2].customerCN60,
      aging60AR: CNData[3].customerCN60,
      aging90AR: CNData[4].customerCN60,
      aging180AR: CNData[5].customerCN60,
      aging365AR: CNData[6].customerCN60,
      aging365PlusAR: CNData[7].customerCN60,
      rangeId: 3,
    },

    { path: "Net 90", id: 9 },
    {
      id: 10,
      path: "Net 90/Total Sum",
      totalAR: CNData[0].totalCN90,
      currentAR: CNData[1].totalCN90,
      aging30AR: CNData[2].totalCN90,
      aging60AR: CNData[3].totalCN90,
      aging90AR: CNData[4].totalCN90,
      aging180AR: CNData[5].totalCN90,
      aging365AR: CNData[6].totalCN90,
      aging365PlusAR: CNData[7].totalCN90,
      rangeId: 4,
    },
    {
      id: 11,
      path: "Net 90/Number of customer",
      totalAR: CNData[0].customerCN90,
      currentAR: CNData[1].customerCN90,
      aging30AR: CNData[2].customerCN90,
      aging60AR: CNData[3].customerCN90,
      aging90AR: CNData[4].customerCN90,
      aging180AR: CNData[5].customerCN90,
      aging365AR: CNData[6].customerCN90,
      aging365PlusAR: CNData[7].customerCN90,
      rangeId: 4,
    },

    { path: "Net 120", id: 12 },
    {
      id: 13,
      path: "Net 120/Total Sum",
      totalAR: CNData[0].totalCN120,
      currentAR: CNData[1].totalCN120,
      aging30AR: CNData[2].totalCN120,
      aging60AR: CNData[3].totalCN120,
      aging90AR: CNData[4].totalCN120,
      aging180AR: CNData[5].totalCN120,
      aging365AR: CNData[6].totalCN120,
      aging365PlusAR: CNData[7].totalCN120,
      rangeId: 5,
    },
    {
      id: 14,
      path: "Net 120/Number of customer",
      totalAR: CNData[0].customerCN120,
      currentAR: CNData[1].customerCN120,
      aging30AR: CNData[2].customerCN120,
      aging60AR: CNData[3].customerCN120,
      aging90AR: CNData[4].customerCN120,
      aging180AR: CNData[5].customerCN120,
      aging365AR: CNData[6].customerCN120,
      aging365PlusAR: CNData[7].customerCN120,
      rangeId: 5,
    },

    { path: "Net 180", id: 15 },
    {
      id: 16,
      path: "Net 180/Total Sum",
      totalAR: CNData[0].totalCN180,
      currentAR: CNData[1].totalCN180,
      aging30AR: CNData[2].totalCN180,
      aging60AR: CNData[3].totalCN180,
      aging90AR: CNData[4].totalCN180,
      aging180AR: CNData[5].totalCN180,
      aging365AR: CNData[6].totalCN180,
      aging365PlusAR: CNData[7].totalCN180,
      rangeId: 6,
    },
    {
      id: 17,
      path: "Net 180/Number of customer",
      totalAR: CNData[0].customerCN180,
      currentAR: CNData[1].customerCN180,
      aging30AR: CNData[2].customerCN180,
      aging60AR: CNData[3].customerCN180,
      aging90AR: CNData[4].customerCN180,
      aging180AR: CNData[5].customerCN180,
      aging365AR: CNData[6].customerCN180,
      aging365PlusAR: CNData[7].customerCN180,
      rangeId: 6,
    },

    { path: "Other", id: 18 },
    {
      id: 19,
      path: "Other/Total Sum",
      totalAR: CNData[0].totalOther,
      currentAR: CNData[1].totalOther,
      aging30AR: CNData[2].totalOther,
      aging60AR: CNData[3].totalOther,
      aging90AR: CNData[4].totalOther,
      aging180AR: CNData[5].totalOther,
      aging365AR: CNData[6].totalOther,
      aging365PlusAR: CNData[7].totalOther,
      rangeId: 7,
    },
    {
      id: 20,
      path: "Other/Number of customer",
      totalAR: CNData[0].customerOther,
      currentAR: CNData[1].customerOther,
      aging30AR: CNData[2].customerOther,
      aging60AR: CNData[3].customerOther,
      aging90AR: CNData[4].customerOther,
      aging180AR: CNData[5].customerOther,
      aging365AR: CNData[6].customerOther,
      aging365PlusAR: CNData[7].customerOther,
      rangeId: 7,
    },
  ];

  return rows;
};

export const CollectionV2CVM = {
  __tree_data_group__: true,
  totalAR: true,
  currentAR: true,
  aging30AR: true,
  aging60AR: true,
  aging90AR: true,
  aging180AR: true,
  aging365AR: true,
  aging365PlusAR: true,
};

export const AgingEnum = {
  "Total Open Balance": "Total AR Balance",
  "Open Balance Current": "Current AR Balance",
  "Open Balance Age 30": "AR Balance Aging 30",
  "Open Balance Age 60": "AR Balance Aging 60",
  "Open Balance Age 90": "AR Balance Aging 90",
  "Open Balance Age 180": "AR Balance Aging 180",
  "Open Balance Age 365": "AR Balance Aging 365",
  "Open Balance Age 365+": "AR Balance Aging 365+",
};
