import { API, graphqlOperation } from "aws-amplify";
import { createContext, useContext, useState, useEffect, ReactNode } from "react";
import { listDashboardPreferences } from "../graphql/queries"; 
import { updateDashboardPreferences } from "../graphql/mutations"; 
import { getTenantIdAuth0, isAdminAuth0 } from "../authUtils";
import getTenantIdFromURL from "../utils/getTenantIdFromURL";

const UserPreferencesContext = createContext(undefined);

export const UserPreferencesProvider = ({ children }) => {
  const [userPreferences, setUserPreferences] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const isAdmin = await isAdminAuth0();
        const selectedTenant = getTenantIdFromURL();
        const tenantId = isAdmin ? selectedTenant : await getTenantIdAuth0();
        console.log("tenantId here in preference",tenantId)
        const result = await API.graphql(
          graphqlOperation(listDashboardPreferences, { tenantId })
        );

        console.log("INFO User Preferences result -> ", JSON.stringify(result.data.listDashboardPreferences.items, null, 2));
        
        const preferences = {};
        result.data.listDashboardPreferences.items.forEach((item) => {
          preferences[item.feature] = JSON.parse(item.preferences); 
        });
        
        setUserPreferences(preferences);
        console.log("INFO User Preferences Standard -> ", preferences);
      } catch (error) {
        console.error("Unable to fetch user preferences. Error JSON:", JSON.stringify(error, null, 2));
      } finally {
        setLoading(false);
      }
    };

    fetchUserPreferences();
  }, []);

  const updateUserPreferences = async (feature, newPreferences) => {
    try {
      const jsonString = JSON.stringify(newPreferences); 

      const result = await API.graphql(
        graphqlOperation(updateDashboardPreferences, {
          input: {
            tenantId: await getTenantIdAuth0(), 
            feature: feature,
            preferences: jsonString, 
          },
        })
      );

      console.log("Updated User Preferences result:", JSON.stringify(result, null, 2));

      setUserPreferences((prevPreferences) => ({
        ...prevPreferences,
        [feature]: JSON.parse(jsonString),
      }));
    } catch (error) {
      console.error("Unable to update user preferences. Error JSON:", JSON.stringify(error, null, 2));
    }
  };

  return (
    <UserPreferencesContext.Provider value={{ userPreferences, updateUserPreferences, loading }}>
      {children}
    </UserPreferencesContext.Provider>
  );
};

export const useUserPreferences = () => {
  const context = useContext(UserPreferencesContext);
  if (!context) {
    throw new Error("useUserPreferences must be used within a UserPreferencesProvider");
  }
  return context;
};
