import React, { useEffect, useState, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button, Menu, MenuItem, styled } from "@mui/material";
import { useFeatureFlag } from "./context/featureFlagContext";
import { useLoaderData, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { subscribe, unsubscribe } from "./events";
import Divider from "@mui/material/Divider";
import { API, Amplify, graphqlOperation } from "aws-amplify";
import AWS from "aws-sdk";
import awsExports from "./aws-exports";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { List } from "@mui/material";
import Green from "./Assets/Images/smiley_green.png";
import Red from "./Assets/Images/smiley_red.png";
import Yellow from "./Assets/Images/smiley_yellow.png";
import RightIcon from "./Assets/Images/right.svg";
import LeftIcon from "./Assets/Images/left.svg";

import Null from "./Assets/Images/null.svg";
import Bar from "./Assets/Images/bar.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import Refresh from "./Assets/Images/refresh.svg";
import { getUnreadAlerts } from "./utils/GraphQLHelper/AlertTable";
import Bulb from "./Assets/Images/bulb.svg";
import locationIcon from "./Assets/Images/location.svg";
import bgArc from "./Assets/Images/bg-arc.svg";
import {
  listLastVisitedCompanies,
  triggerQuestionsAgent,
} from "./graphql/queries";
import { onCreateAlert } from "./graphql/subscriptions";
import {
  createLastVisitedCompanies,
  deleteLastVisitedCompanies,
} from "./graphql/mutations.js";
import { useSearchQuery } from "./context/searchContext.js";
import { fetchFileFromS3, fetchSummaryJSON, formatCurrencyValue, normalizeRowData } from "./utils/Helper.js";
import FilterDialog from './FilterDialog';
import { useFilter } from './context/filterContext';

Amplify.configure(awsExports);


const getScoreImage = (score) => {
  if (score >= 1 && score < 4) {
    return Green;
  } else if (score >= 4 && score < 8) {
    return Yellow;
  } else if (score >= 8) {
    return Red;
  }
  return null;
};

const getScoreColor = (score) => {
  if (score >= 1 && score < 4) {
    return '#60AC50'; 
  } else if (score >= 4 && score < 8) {
    return '#E7BF3C';
  } else if (score >= 8) {
    return '#F25A48'; 
  }
  return '#000000'; 
};

const BarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Bars = styled('div')(({ filled }) => ({
  width: "3px",
  height: "7px",
  backgroundColor: filled ? '#FB8802' : '#777A82',
  borderRadius: "3px",
  margin: '0 2px',
}));

const getNumericalValue = (value) => {
  return value === null || value === undefined || value === "" ? 0 : parseFloat(value);
};

function CustomBarRating({ maxValue = 10, value = 4 }) {

  return (
    <BarContainer sx={{marginBottom:1}}>
      {[...Array(maxValue)].map((_, index) => (
        <Bars 
          key={index} 
          filled={index < value}
        />
      ))}
    </BarContainer>
  );
}

const CompanyCard = ({ company, adminAction, alerts, tenantId, summaryData }) => {
  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const navigate = useNavigate();
  const selectedTenant = getTenantIdFromURL();
  const summary = summaryData.find(companySummary => companySummary["Company Name"] === company?.name);
  const currentCompany = company?.summary ? JSON.parse(company?.summary) : {};
  const timer = React.useRef();
  const [isExecuting, setIsExecuting] = useState(false);

  const handleGoToCompany = async (id) => {
    if (isExecuting) {
      return;
    }
    setIsExecuting(true);
    try {
      const result = await API.graphql(
        graphqlOperation(listLastVisitedCompanies, {
          tenantId: tenantId,
          sortDirection: "DESC",
        })
      );

      console.log(
        "fetched lastvisited",
        result.data.listLastVisitedCompanies.items
      );
      const lastVisitedCompanies = result.data.listLastVisitedCompanies.items;
      const found = lastVisitedCompanies.find(
        (visitedCompany) => visitedCompany.companyId === company?.id
      ); // take care of state variable company and local company variables
      const currentTime = new Date().toISOString();
      console.log("found", found);
      console.log("current time", currentTime);
      if (found) {
        await API.graphql(
          graphqlOperation(deleteLastVisitedCompanies, {
            input: {
              tenantId: found.tenantId,
              lastOpenedAt: found.lastOpenedAt,
            },
          })
        );
        console.log(`deleted lastOpenedAt for company ID: ${company?.id}`);
      }
      await API.graphql(
        graphqlOperation(createLastVisitedCompanies, {
          input: {
            tenantId: tenantId,
            companyId: company?.id,
            lastOpenedAt: currentTime,
          },
        })
      );
      console.log(`Created new entry for company ID: ${company?.id}`);

      if (isAdmin) {
        navigate(`/tenant-dashboard/${selectedTenant}/company/${id}`);
      } else {
        navigate(`/company/${id}`);
      }
      setIsExecuting(false);
    } catch (error) {
      setIsExecuting(false);
      console.error(error);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [dnbJsonData, setDnbJsonData] = React.useState("");
  const [companyDnbID, setCompanyDnbID] = React.useState(null);
  const [disabled, setDisabled] = useState(false);

  const [latestAlert, setLatestAlert] = React.useState();
  const [foundedYear, setFoundedYear] = React.useState("N/A");
  const [yearlyRevenue, setYearlyRevenue] = React.useState("N/A");
  const open = Boolean(anchorEl);
  const companyRecord = company?.companyInfo ? JSON.parse(company?.companyInfo) : {};

  const WikiInfobox = companyRecord?.infobox
    ? JSON.parse(companyRecord.infobox)
    : {};
  const confidenceLevel = companyRecord?.confidenceLevel || 0 ;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = (action) => {
    console.log(`Performing action: ${action}`);
    handleClose();
  };

  const handleRefreshClick = () => {
    onTriggerAgentClick();
    // getSummaryData();
  };

  const formatToUSD = (yearlyRevenue) => {
    let usdRevenue = null;
    for (let revenue of yearlyRevenue) {
      if (revenue.currency === "USD") {
        usdRevenue = revenue.value;
        break;
      }
    }

    if (usdRevenue !== null) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(usdRevenue);
    } else {
      return "N/A";
    }
  };
  function extractYear(dateString) {
    if (typeof dateString !== "string") {
      return "N/A";
    }

    const yearPattern = /\b(\d{4})\b/;
    const monthDayYearPattern =
      /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{1,2},\s+(\d{4})\b/;
    const monthYearPattern =
      /\b(?:January|February|March|April|May|June|July|August|September|October|November|December)\s+(\d{4})\b/;

    let match = dateString.match(monthDayYearPattern);
    if (match) {
      return match[1];
    }

    match = dateString.match(monthYearPattern);
    if (match) {
      return match[1];
    }

    match = dateString.match(yearPattern);
    if (match) {
      return match[1];
    }

    return "N/A";
  }
  const getFounded = () => {
    if (WikiInfobox?.founded?.manual === true) {
      return WikiInfobox.founded.value;
    } else if (WikiInfobox?.foundation?.manual === true) {
      return WikiInfobox.foundation.value;
    } else if (WikiInfobox?.foundation) {
      return WikiInfobox.foundation;
    } else if (WikiInfobox?.founded) {
      return WikiInfobox.founded;
    } else {
      return "N/A";
    }
  };
  const getRevenue = () => {
    if (WikiInfobox?.revenue?.manual === true) {
      return WikiInfobox.revenue.value;
    } else if (WikiInfobox?.revenue) {
      return WikiInfobox.revenue;
    } else {
      const allRevenues =
        dnbJsonData?.[0]?.response?.organization?.financials?.[0]
          ?.yearlyRevenue || [];
      const usdRevenue = formatToUSD(allRevenues);
      return usdRevenue;
    }
  };
  const getSymbol = () => {
    if (companyRecord?.ticker) {
      return companyRecord.ticker;
    } else if (WikiInfobox?.traded_as?.manual === true) {
      return WikiInfobox.traded_as.value;
    } else if (WikiInfobox?.traded_as) {
      return WikiInfobox.traded_as;
    } else {
      return "";
    }
  };
  useEffect(() => {
    const date = companyRecord?.founded ? companyRecord.founded : "N/A";
    let founded = extractYear(date);
    if (founded === "N/A") {
      founded = getFounded;
    }
    setFoundedYear(founded);
    const allRevenues =
      dnbJsonData &&
      dnbJsonData[0]?.response?.organization?.financials[0]?.yearlyRevenue
        ? dnbJsonData &&
          dnbJsonData[0]?.response?.organization?.financials[0]?.yearlyRevenue
        : [];
    let usdRevenue = formatToUSD(allRevenues);
    if (usdRevenue === "N/A") {
      usdRevenue = getRevenue();
    }
    setYearlyRevenue(usdRevenue);
    const filteredAlerts = alerts?.filter((alert) => {
      const companyInfo = JSON.parse(alert.companyInfo);
      if (
        alert.tenantId !== tenantId ||
        company?.name !== companyInfo?.companyName
      )
        return false;
      else return true;
    });
    if (filteredAlerts.length != 0) setLatestAlert(filteredAlerts[0].message);
    //  dnb
    setCompanyDnbID(companyRecord?.dnbId);
  }, [alerts, company]);

  React.useEffect(() => {
    const fetchDnbDataFromJson = async () => {
      if (companyDnbID) {
        try {
          const key = "public/company-info/info-" + companyDnbID + ".json";
          let summary = await fetchFileFromS3(key)
          summary = normalizeRowData(summary)
          setDnbJsonData(summary);
        } catch (error) {
          setDnbJsonData(undefined);
        }
      }
    };
    fetchDnbDataFromJson();
  }, [companyDnbID]);

  const onTriggerAgentClick = async () => {
    toast.success(`Trigger Agent started!`, {
      bodyStyle: {
        fontWeight: "500",
      },
    });
    setDisabled(true);
    await API.graphql(
      graphqlOperation(triggerQuestionsAgent, {
        tenantId: company?.tenantId,
        id: company?.id,
      })
    );
    console.log("triggered");
    timer.current = window.setTimeout(() => {
      setDisabled(false);
    }, 16000);
  };
  const getNumberOfEmployees = () => {
    if (
      dnbJsonData &&
      dnbJsonData[0]?.response?.organization?.numberOfEmployees[0]?.value
    ) {
      return (
        "(est.) " +
        dnbJsonData[0].response.organization.numberOfEmployees[0].value
      );
    } else if (WikiInfobox?.num_employees?.manual === true) {
      return "(est.) " + WikiInfobox.num_employees.value;
    } else if (WikiInfobox?.num_employees) {
      return "(est.) " + WikiInfobox.num_employees;
    } else {
      return "N/A";
    }
  };
  const getLocation = () => {
    if (companyRecord?.country) {
      return companyRecord.country;
    } else if (WikiInfobox?.hq_location_country?.manual === true) {
      return (
        WikiInfobox.hq_location_country.value +
        " , " +
        WikiInfobox.hq_location_city.value
      );
    } else if (WikiInfobox?.hq_location_country) {
      return (
        WikiInfobox.hq_location_country + " , " + WikiInfobox.hq_location_city
      );
    } else {
      return "N/A";
    }
  };
  const cardStyle = {
    background: "#FAFAFC 0% 0% no-repeat padding-box",
    boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
    borderRadius: "8px",
    width: "440px",
    height: "300px",
    padding: "20px 20px 0 20px",
    position: "relative",
  };

  const bgStyle = {
    width: "100%",
    height: "100%",
    background: `transparent url(${bgArc}) 0% 0% no-repeat padding-box`,
    // opacity: "0.03",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  };
  const avatarStyle = {
    width: 45,
    height: 45,
    cursor: "pointer",
    borderRadius: "50%",
    boxShadow: "0px 4px 6px #00000029",
  };
  const sideBoxStyle = {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px",
    alignItems: "center",
    gap: "5px",
  };
  const scoreImage =
    currentCompany && currentCompany["Combined Score"]
      ? getScoreImage(currentCompany["Combined Score"])
      : null;
  const scoreColor =
    currentCompany && currentCompany["Combined Score"]
      ? getScoreColor(currentCompany["Combined Score"])
      : "#000";

  function getWebsiteLink(data) {
    if (data) {
      const { organicResult } = data;
      let websiteLink = companyRecord?.website;
      // let websiteLink = knowledgeGraph?.website;

      if (!websiteLink && organicResult?.items) {
        const companyNameWords = company?.name.split(" ") || [];
        for (const element of organicResult.items) {
          for (const word of companyNameWords) {
            const urlRegex =
              /^(http|https):\/\/(?!.*(linkedin\.com|youtube\.com|facebook\.com|twitter\.com|instagram\.com|pinterest\.com|tiktok\.com|snapchat\.com|reddit\.com))[^\s/$.?#].[^\s]*$/;
            if (
              element?.source.toLowerCase().includes(word.toLowerCase()) &&
              urlRegex.test(element?.link)
            ) {
              websiteLink = element?.link;
              return websiteLink;
            }
          }
        }
      }
      return websiteLink;
    }
  }

  const website = getWebsiteLink(companyRecord);
  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value) &&
      value!=="NaN"
    );
  }

  return (
    <Card sx={cardStyle}>
      <div style={bgStyle}></div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          zIndex: 1,
        }}
      >
        <div style={{ width: "320px" }}>
          <CardHeader
            onClick={() => handleGoToCompany(company?.id)}
            disabled={isExecuting}
            title={
              <Typography
                sx={{
                  cursor: "pointer",
                  fontWeight: "500",
                  color: "#1A2A56",
                  fontSize: "20px",
                }}
                variant="h5"
                component="span"
              >
                {company?.name?.substring(0, 15) +
                  (company?.name?.length > 15 ? "..." : "")}
              </Typography>
            }
            sx={{ padding: "0px", zIndex: 1 }}
            subheader={
              <Typography
                sx={{
                  color: "#777A82",
                  maxWidth: 200,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "12px",
                  fontWeight: "400",
                  whiteSpace: "nowrap",
                }}
              >
                {companyRecord?.type}
              </Typography>
            }
            avatar={
              <Avatar
                onClick={() => handleGoToCompany(company?.id)}
                sx={avatarStyle}
                alt={company?.name}
                src={
                  companyRecord?.logo ||
                  // google favicon search
                  `https://www.google.com/s2/favicons?sz=64&domain=${website}`
                }
              />
            }
          />
          <CardContent
            sx={{
              height: 120,
              maxHeight: 120,
              padding: 0,
              overflow: "hidden",
            }}
          >
            <div
              style={{
                //grid
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "10px 0 0 10px",
                height: "100%",
                overflowY: "scroll",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <style>
                {`
              .hide-scrollbar::-webkit-scrollbar {
                display: none;
              }
              .hide-scrollbar {
                -ms-overflow-style: none;  
                scrollbar-width: none;  
              }
            `}
              </style>
              <div
                className="hide-scrollbar"
                style={{ height: "100%", overflowY: "scroll" }}
              >
                {latestAlert ? (
                  <div
                    style={{ display: "flex", gap: "5px", marginTop: "5px" }}
                  >
                    <img
                      src={Bulb}
                      style={{ width: "20px", height: "22px" }}
                      alt="Bulb Icon"
                    />
                    <Typography sx={{ fontSize: "14px", color: "#2F3D63" }}>
                      {latestAlert}
                    </Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </CardContent>
        </div>
        <div style={sideBoxStyle}>
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              alignSelf: "flex-end",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: scoreColor,
                fontWeight: "500",
              }}
            >
              {isValid(currentCompany?.["Combined Score"])
                ? currentCompany?.["Combined Score"]
                : "-"}
            </Typography>
            <img
              src={scoreImage ? scoreImage : Null}
              style={{ width: "16px", height: "18px" }}
              alt=""
            />
          </div>
          <div style={{ alignSelf: "flex-end", marginBottom: "5px" }}>
            <Typography
              sx={{
                fontSize: "14px",
                color: "#777A82",
                alignSelf: "flex-end",
                textAlign: "right",
              }}
            >
              Rec. Limit:
            </Typography>
            <Typography
              sx={{ fontSize: "19px", color: "#2F3D63", fontWeight: "500" }}
            >
              $
              {currentCompany && currentCompany["Recommended Limit"]
                ? currentCompany["Recommended Limit"]
                : "  -"}
            </Typography>
          </div>
          <Box sx={{ alignSelf: "flex-end" }}>
            <CustomBarRating value={confidenceLevel}></CustomBarRating>
          </Box>
          <div style={{ alignSelf: "flex-end" }}>
            {/* <Button
              id="action-button"
              aria-controls={open ? "action-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              color="primary"
              disabled={false}
              sx={{
                background: "#5186EC 0% 0% no-repeat padding-box",
                borderRadius: "6px",
                backgroundColor: "#5186EC",
                width: "90px",
                height: "30px",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "400",
              }}
              endIcon={<ExpandMoreIcon />}
              onClick={handleClick}
            >
              Actions
            </Button> */}
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "action-button",
              }}
            >
              <MenuItem onClick={() => handleActionClick("action1")}>
                Action 1
                    </MenuItem>
              <hr />

              <MenuItem onClick={() => handleActionClick("action2")}>
                Action 2
                </MenuItem>
              <hr />

              <MenuItem onClick={() => handleActionClick("action3")}>
                Action 3
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px 0 0 10px",
          height: "60px",
        }}
      >
        <div>
          <EmojiFlagsIcon style={{ color: "#777A82" }} />
          <br />
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxHeight: "45px",
              maxWidth: "90px",
              display: "block",
            }}
            color="#2F3D63"
          >
            {foundedYear}
          </Typography>
        </div>
        <div>
          <LocalAtmIcon style={{ color: "#777A82" }} />
          <br />
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxHeight: "45px",
              maxWidth: "90px",
              display: "block",
            }}
            color="#2F3D63"
          >
            {yearlyRevenue}
          </Typography>
        </div>
        <div>
          <GroupsOutlinedIcon style={{ color: "#777A82" }} />
          <br />
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxHeight: "45px",
              maxWidth: "90px",
              display: "block",
            }}
            color="#2F3D63"
          >
            {getNumberOfEmployees()}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "0px",
          }}
        >
          <img
            src={locationIcon}
            style={{
              height: "24px",
              maxWidth: "100%",
              alignSelf: "flex-start",
            }}
          />
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxHeight: "45px",
              maxWidth: "90px",
              display: "block",
            }}
            color="#2F3D63"
          >
            {getLocation()}&nbsp;
          </Typography>
        </div>
      </div>
      <Divider />
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "right",
          padding: "16px 0 24px 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Button
            sx={{ minWidth: "0px", padding: "0px", height: "fit-content" }}
            onClick={handleRefreshClick}
            disabled={disabled}
          >
            <img src={Refresh} alt="" />
          </Button>
          <Typography variant="body2" color="text.secondary">
            {company?.updatedAtFormatted}&nbsp;
          </Typography>
        </div>
        {getSymbol() ? (
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <div
              style={{
                height: "10px",
                width: "10px",
                background: "#3097D2 0% 0% no-repeat padding-box",
                borderRadius: "50%",
              }}
            ></div>
            <Typography variant="body2" color="text.secondary">
              {getSymbol()}
            </Typography>
          </div>
        ) : (
          " "
        )}
      </CardContent>
    </Card>
  );
};

export default function CompaniesTable({ adminAction }) {
  const navigate = useNavigate();
  let { companies } = useLoaderData();

  const [companyInfo, setCompanyInfo] = useState(null);
  const [summaryData, setSummaryData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [tenantId, setTenantId] = useState();
  const [showLeftButton, setShowLeftButton] = useState({
    newCompanies: false,
    updatedCompanies: false,
    riskyCompanies: false,
    visitedCompanies: false,
  });
  const [showRightButton, setShowRightButton] = useState({
    newCompanies: true,
    updatedCompanies: true,
    riskyCompanies: true,
    visitedCompanies: true,
  });

  const [initialRender, setInitialRender] = useState(true);
  const [newCompanies, setNewCompanies] = useState([]);
  const [recentlyUpdatedCompanies, setRecentlyUpdatedCompanies] = useState([]);
  const [recentlyVisitedCompanies, setRecentlyVisitedCompanies] = useState([]);
  const [riskyCompanies, setRiskyCompanies] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const newCompaniesListRef = useRef(null);
  const recentlyUpdatedCompaniesListRef = useRef(null);
  const riskyCompaniesListRef = useRef(null);
  const recentlyVisitedCompaniesListref = useRef(null);
  const isAdmin = useSelector((state) => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant = useSelector((state) => state.userInfo.tenantId);
  const { searchQuery, setSearchQuery } = useSearchQuery();
  console.log("searchQuery", searchQuery);
  const STRING_OPERATORS = {
    IS: 'is',
    STARTS_WITH: 'starts with',
    ENDS_WITH: 'ends with',
    CONTAINS: 'contains'
  };
  const NUMBER_OPERATORS = {
    EQUALS: '=',
    GREATER_THAN: '>',
    LESS_THAN: '<',
    GREATER_THAN_EQUALS: '>=',
    LESS_THAN_EQUALS: '<='
  };
  const DATE_OPERATORS = {
    EQUALS: '=',
    BEFORE: '<',
    AFTER: '>',
    BEFORE_OR_ON: '<=',
    AFTER_OR_ON: '>='
  };
  const { filterOpen, handleFilterClose } = useFilter();

  const defaultFilters = {
    companyName: {
      value: '',
      operator: STRING_OPERATORS.CONTAINS
    },
    requestedLimit: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    recommendedLimit: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    arcScore: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    rationale: {
      value: '',
      operator: STRING_OPERATORS.CONTAINS
    },
    createdAt: {
      value: '',
      operator: DATE_OPERATORS.EQUALS
    },
    paymentIndex: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    arInArrear: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    }
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [tempFilters, setTempFilters] = useState(defaultFilters);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const calculateArInArrear = (summary) => {
    if (!summary) return 0;
    
    return formatCurrencyValue(
      formatCurrencyValue(summary["AR Balance Aging 15"]) +
      formatCurrencyValue(summary["AR Balance Aging 30"]) +
      formatCurrencyValue(summary["AR Balance Aging 45"]) +
      formatCurrencyValue(summary["AR Balance Aging 60"]) +
      formatCurrencyValue(summary["AR Balance Aging 60+"]) +
      formatCurrencyValue(summary["AR Balance Aging 90"]) +
      formatCurrencyValue(summary["AR Balance Aging 90+"]) +
      formatCurrencyValue(summary["AR Balance Aging 120"]) +
      formatCurrencyValue(summary["AR Balance Aging 120+"]) +
      formatCurrencyValue(summary["AR Balance Aging 180"]) +
      formatCurrencyValue(summary["AR Balance Aging 180+"]) +
      formatCurrencyValue(summary["AR Balance Aging 365"]) +
      formatCurrencyValue(summary["AR Balance Aging 365+"])
    );
  };

  const normalizeDate = (date) => {
    const d = new Date(date);
    // Reset time to midnight UTC
    return new Date(d.getFullYear(), d.getMonth(), d.getDate()).getTime();
  };

  const applyFilters = (companies, currentFilters) => {
    return companies.filter(company => {
      let summary;
      try {
        summary = company?.summary ? JSON.parse(company.summary) : {};
      } catch (error) {
        console.warn('Error parsing company summary:', error);
        summary = {};
      }
      
      if (currentFilters.requestedLimit.value) {
        const requestedLimit = formatCurrencyValue(summary?.["Requested Limit"]);
        const filterValue = formatCurrencyValue(currentFilters.requestedLimit.value);
        
        switch (currentFilters.requestedLimit.operator) {
          case NUMBER_OPERATORS.EQUALS:
            if (requestedLimit !== filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN:
            if (requestedLimit <= filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN:
            if (requestedLimit >= filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN_EQUALS:
            if (requestedLimit < filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN_EQUALS:
            if (requestedLimit > filterValue) return false;
            break;
        }
      }

      if (currentFilters.arcScore.value) {
        const arcScore = getNumericalValue(summary?.["Combined Score"]);
        const filterValue = getNumericalValue(currentFilters.arcScore.value);

        if (isNaN(arcScore) || isNaN(filterValue)) return false;
        
        switch (currentFilters.arcScore.operator) {
          case NUMBER_OPERATORS.EQUALS:
            if (arcScore !== filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN:
            if (arcScore <= filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN:
            if (arcScore >= filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN_EQUALS:
            if (arcScore < filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN_EQUALS:
            if (arcScore > filterValue) return false;
            break;
        }
      }

      if (currentFilters.recommendedLimit.value) {
        const recommendedLimit = formatCurrencyValue(summary?.["Recommended Limit"]);
        const filterValue = formatCurrencyValue(currentFilters.recommendedLimit.value);
        
        switch (currentFilters.recommendedLimit.operator) {
          case NUMBER_OPERATORS.EQUALS:
            if (recommendedLimit !== filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN:
            if (recommendedLimit <= filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN:
            if (recommendedLimit >= filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN_EQUALS:
            if (recommendedLimit < filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN_EQUALS:
            if (recommendedLimit > filterValue) return false;
            break;
        }
      }

      if (currentFilters.arInArrear.value) {
        const arInArrear = calculateArInArrear(summary);
        const filterValue = formatCurrencyValue(currentFilters.arInArrear.value);
        
        switch (currentFilters.arInArrear.operator) {
          case NUMBER_OPERATORS.EQUALS:
            if (arInArrear !== filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN:
            if (arInArrear <= filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN:
            if (arInArrear >= filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN_EQUALS:
            if (arInArrear < filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN_EQUALS:
            if (arInArrear > filterValue) return false;
            break;
        }
      }
      if (currentFilters.companyName.value) {
        const companyName = company?.name?.toLowerCase() || "";
        const nameFilterValue = currentFilters.companyName.value.toLowerCase();
        
        switch (currentFilters.companyName.operator) {
          case STRING_OPERATORS.IS:
            if (companyName !== nameFilterValue) return false;
            break;
          case STRING_OPERATORS.STARTS_WITH:
            if (!companyName.startsWith(nameFilterValue)) return false;
            break;
          case STRING_OPERATORS.ENDS_WITH:
            if (!companyName.endsWith(nameFilterValue)) return false;
            break;
          case STRING_OPERATORS.CONTAINS:
            if (!companyName.includes(nameFilterValue)) return false;
            break;
          default:
            break;
        }
      }

      if (currentFilters.rationale.value) {
        const rationale = summary?.["Underwriting Reason"]?.toLowerCase() || '';
        const rationaleFilterValue = currentFilters.rationale.value.toLowerCase();
        
        switch (currentFilters.rationale.operator) {
          case STRING_OPERATORS.IS:
            if (rationale !== rationaleFilterValue) return false;
            break;
          case STRING_OPERATORS.STARTS_WITH:
            if (!rationale.startsWith(rationaleFilterValue)) return false;
            break;
          case STRING_OPERATORS.ENDS_WITH:
            if (!rationale.endsWith(rationaleFilterValue)) return false;
            break;
          case STRING_OPERATORS.CONTAINS:
            if (!rationale.includes(rationaleFilterValue)) return false;
            break;
          default:
            break;
        }
      }

      if (currentFilters.createdAt.value) {
        const createdAt = normalizeDate(company.createdAt);
        const dateFilterValue = normalizeDate(currentFilters.createdAt.value);
        
        console.log('Normalized createdAt:', new Date(createdAt));
        console.log('Normalized filterValue:', new Date(dateFilterValue));

        switch (currentFilters.createdAt.operator) {
          case DATE_OPERATORS.EQUALS:
            if (createdAt !== dateFilterValue) return false;
            break;
          case DATE_OPERATORS.BEFORE:
            if (createdAt >= dateFilterValue) return false;
            break;
          case DATE_OPERATORS.AFTER:
            if (createdAt <= dateFilterValue) return false;
            break;
          case DATE_OPERATORS.BEFORE_OR_ON:
            if (createdAt > dateFilterValue) return false;
            break;
          case DATE_OPERATORS.AFTER_OR_ON:
            if (createdAt < dateFilterValue) return false;
            break;
        }
      }

      if (currentFilters.paymentIndex.value) {
        const paymentIndex = formatCurrencyValue(summary?.["paydex"]);
        const filterValue = formatCurrencyValue(currentFilters.paymentIndex.value);
        
        switch (currentFilters.paymentIndex.operator) {
          case NUMBER_OPERATORS.EQUALS:
            if (paymentIndex !== filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN:
            if (paymentIndex <= filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN:
            if (paymentIndex >= filterValue) return false;
            break;
          case NUMBER_OPERATORS.GREATER_THAN_EQUALS:
            if (paymentIndex < filterValue) return false;
            break;
          case NUMBER_OPERATORS.LESS_THAN_EQUALS:
            if (paymentIndex > filterValue) return false;
            break;
        }
      }

      return true;
    });
  };


  const handleApplyFilters = (newFilters) => {
    const hasActiveFilters = Object.values(newFilters).some(filter => filter.value);
    
    if (hasActiveFilters) {
      setFilters(newFilters);
      const filtered = applyFilters(companies, newFilters);
      setFilteredCompanies(filtered);
      setIsFiltered(true);
    } else {
      setFilters(defaultFilters);
      setFilteredCompanies([]);
      setIsFiltered(false);
    }
  };

  const selectTenantId = () => {
    if (isAdmin) setTenantId(URLTenant);
    else setTenantId(currentTenant);
  };

  const handleSetCompanyInfo = (value) => {
    setCompanyInfo(value);
  };

  const setCompanyInfoToNull = () => {
    setCompanyInfo(null);
  };

  const [isScrolling, setIsScrolling] = useState(false);
  const handleScroll = (listRef, listType) =>
    debounce(() => {
      if (listRef.current) {
        const newScrollLeft = listRef.current.scrollLeft;
        const maxScrollLeft =
          listRef.current.scrollWidth - listRef.current.clientWidth - 440;
        console.log("maxscrolleft", maxScrollLeft);
        console.log("newScrollLeft", newScrollLeft);
        setShowLeftButton((prevState) => ({
          ...prevState,
          [listType]: newScrollLeft > 0,
        }));
        setShowRightButton((prevState) => ({
          ...prevState,
          [listType]: newScrollLeft < maxScrollLeft,
        }));
        setIsScrolling(false);
      }
    }, 100);

  useEffect(() => {
    const handleScrollNewCompanies = handleScroll(
      newCompaniesListRef,
      "newCompanies"
    );
    if (newCompaniesListRef.current) {
      newCompaniesListRef.current.addEventListener(
        "scroll",
        handleScrollNewCompanies
      );
    }

    return () => {
      if (newCompaniesListRef.current) {
        newCompaniesListRef.current.removeEventListener(
          "scroll",
          handleScrollNewCompanies
        );
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    const handleScrollUpdatedCompanies = handleScroll(
      recentlyUpdatedCompaniesListRef,
      "updatedCompanies"
    );
    if (recentlyUpdatedCompaniesListRef.current) {
      recentlyUpdatedCompaniesListRef.current.addEventListener(
        "scroll",
        handleScrollUpdatedCompanies
      );
    }

    return () => {
      if (recentlyUpdatedCompaniesListRef.current) {
        recentlyUpdatedCompaniesListRef.current.removeEventListener(
          "scroll",
          handleScrollUpdatedCompanies
        );
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    const handleScrollRiskyCompanies = handleScroll(
      riskyCompaniesListRef,
      "riskyCompanies"
    );
    if (riskyCompaniesListRef.current) {
      riskyCompaniesListRef.current.addEventListener(
        "scroll",
        handleScrollRiskyCompanies
      );
    }

    return () => {
      if (riskyCompaniesListRef.current) {
        riskyCompaniesListRef.current.removeEventListener(
          "scroll",
          handleScrollRiskyCompanies
        );
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    const handleScrollVisitedCompanies = handleScroll(
      recentlyVisitedCompaniesListref,
      "visitedCompanies"
    );
    if (recentlyVisitedCompaniesListref.current) {
      recentlyVisitedCompaniesListref.current.addEventListener(
        "scroll",
        handleScrollVisitedCompanies
      );
    }

    return () => {
      if (recentlyVisitedCompaniesListref.current) {
        recentlyVisitedCompaniesListref.current.removeEventListener(
          "scroll",
          handleScrollVisitedCompanies
        );
      }
    };
  }, [searchQuery]);

  useEffect(() => {
    if (searchQuery === "") {
      setShowLeftButton({
        newCompanies: false,
        updatedCompanies: false,
        riskyCompanies: false,
        visitedCompanies: false,
      });
      setShowRightButton({
        newCompanies: true,
        updatedCompanies: true,
        riskyCompanies: true,
        visitedCompanies: true,
      });
    }
  }, [searchQuery]);

  useEffect(() => {
    //setting risky companies
    const dataWithIds = summaryData?.map((obj, index) => {
      const { id, ...rest } = obj;

      return {
        id: index + 1,
        ...rest,
      };
    });

    const allRiskyCompanies = getTop10RiskyCustomer(dataWithIds);

    setRiskyCompanies(allRiskyCompanies);

    //setting new companies
    const currentDate = new Date();
    let pastDate = new Date(currentDate.setDate(currentDate.getDate() - 30));
    let recentCompanies = [...companies];
    recentCompanies.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    recentCompanies = recentCompanies.slice(0, 10);

    setNewCompanies(recentCompanies);

    //setting recently updated companies
    const recentUpdatedCompanies = [];
    pastDate = new Date(currentDate.setDate(currentDate.getDate() - 2));
    companies.forEach((company) => {
      const updatedAt = new Date(company?.updatedAt);
      if (updatedAt >= pastDate) {
        recentUpdatedCompanies.push(company);
      }
    });

    recentUpdatedCompanies.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );
    const topRecentUpdatedCompanies = recentUpdatedCompanies.slice(0, 12);
    setRecentlyUpdatedCompanies(topRecentUpdatedCompanies);

    //setting recently Visited
    const recentVisitedCompanies = [];
    pastDate = new Date(currentDate.setDate(currentDate.getDate() - 1));
    getLastVisitedCompanies();
    recentVisitedCompanies.sort(
      (a, b) => new Date(b.lastOpenedAt) - new Date(a.lastOpenedAt)
    );
    const topRecentVisitedCompanies = recentVisitedCompanies.slice(0, 12);
    setRecentlyVisitedCompanies(topRecentVisitedCompanies);
  }, [companies, tenantId, summaryData]);

  const getLastVisitedCompanies = async () => {
    const result = await API.graphql(
      graphqlOperation(listLastVisitedCompanies, {
        tenantId: tenantId,
      })
    );

    const lastVisitedCompanyIds = result.data.listLastVisitedCompanies.items
      .filter((item) => item.tenantId === tenantId)
      .map((item, index) => ({ id: item.companyId, index }));

    console.log("last visites", lastVisitedCompanyIds);

    lastVisitedCompanyIds.sort((a, b) => b.index - a.index);

    let lastVisitedCompanies = lastVisitedCompanyIds.map(({ id }) => {
      return companies.find((company) => company?.id === id);
    });
    lastVisitedCompanies = lastVisitedCompanies.slice(0, 15);
    setRecentlyVisitedCompanies(lastVisitedCompanies);
  };

  const getTop10RiskyCustomer = (data) => {
    const processedData = data.map((item) => {
      const riskSegment = parseFloat(item["Risk Segment"]);
      const failureScore = parseFloat(item["Failure Score"]);
      const alternativeScore = parseFloat(item["Alternative Score"]);
      const delinquencyScore = parseFloat(item["Delinquency Score"]);

      const sortingScore = calculateSortingScore(
        isNaN(riskSegment) ? undefined : riskSegment,
        isNaN(failureScore) ? undefined : failureScore,
        isNaN(alternativeScore) ? undefined : alternativeScore,
        isNaN(delinquencyScore) ? undefined : delinquencyScore
      );

      return { ...item, SortingScore: sortingScore };
    });

    const sortedData = processedData.slice().sort((a, b) => {
      const scoreA = a.SortingScore || 0;
      const scoreB = b.SortingScore || 0;

      return scoreB - scoreA;
    });

    const matchedCompanies = sortedData
      .map((riskyCompany) => {
        return companies.find(
          (company) => company?.name === riskyCompany["Company Name"]
        );
      })
      .filter((company) => company !== null && company !== undefined)
      .slice(0, 15);
    return matchedCompanies;
  };

  function calculateSortingScore(
    riskSegment,
    failureScore,
    alternativeScore,
    delinquencyScore
  ) {
    let sum = 0;
    let denominator = 0;

    if (riskSegment !== undefined) {
      sum += 5 * riskSegment;
      denominator += 5;
    }

    if (failureScore !== undefined) {
      sum += 3 * failureScore;
      denominator += 3;
    }

    if (alternativeScore !== undefined) {
      sum += 2 * alternativeScore;
      denominator += 2;
    }

    if (delinquencyScore !== undefined) {
      sum += 2 * delinquencyScore;
      denominator += 2;
    }

    if (denominator !== 0) {
      return sum / denominator;
    } else {
      console.error(
        "All scores are missing. Unable to calculate sorting score."
      );
      return undefined;
    }
  }

  const scrollListLeft = useCallback(
    (listRef) => {
      if (listRef.current) {
        const scrollAmount = 3 * (440 + 10) + 30.5;
        const newScrollLeft = Math.max(
          0,
          listRef.current.scrollLeft - scrollAmount
        );
        listRef.current.style.scrollBehavior = "smooth";
        listRef.current.scrollLeft = newScrollLeft;
        setTimeout(() => {
          listRef.current.style.scrollBehavior = "auto";
        }, 500);
      }
      console.log("listRef.current", listRef);
    },
    [initialRender]
  );

  const scrollListRight = useCallback(
    (listRef) => {
      if (listRef.current) {
        const scrollAmount = 3 * (440 + 10) + 30.5;
        const newScrollLeft = listRef.current.scrollLeft + scrollAmount;
        listRef.current.style.scrollBehavior = "smooth";
        listRef.current.scrollLeft = newScrollLeft;
        setTimeout(() => {
          listRef.current.style.scrollBehavior = "auto";
        }, 500);
      }
      console.log("listRef.current", listRef);
    },
    [initialRender]
  );

  // const scrollListLeft = useCallback((listRef) => {
  //   if (listRef.current ) {
  //     const scrollAmount = 3 * (440 + 10) + 30.5;
  //     const newScrollLeft = Math.max(0, listRef.current.scrollLeft - scrollAmount);
  //     listRef.current.style.scrollBehavior = 'smooth';
  //     listRef.current.scrollLeft = newScrollLeft;
  //     setTimeout(() => {
  //       listRef.current.style.scrollBehavior = 'auto';
  //     }, 500);
  //   }
  //   console.log("listRef.current.scrollLeft",listRef.current.scrollLeft)
  //   console.log("listRef.current.offsetWidth",listRef.current.offsetWidth)
  //   console.log("right show",listRef.current.offsetWidth+listRef.current.scrollLeft<listRef.current.scrollWidth)

  // }, []);

  // const scrollListRight = useCallback((listRef) => {
  //   if (listRef.current) {
  //     const scrollAmount = 3 * (440 + 10) + 30.5;
  //     const newScrollLeft = listRef.current.scrollLeft + scrollAmount;
  //     listRef.current.style.scrollBehavior = 'smooth';
  //     listRef.current.scrollLeft = newScrollLeft;
  //     setTimeout(() => {
  //       listRef.current.style.scrollBehavior = 'auto';
  //     }, 500);
  //   }
  //   console.log("listRef.current.scrollLeft",listRef.current.scrollLeft)
  //   console.log("listRef.current.offsetWidth",listRef.current.offsetWidth)
  //   console.log("listRef.current.scrollWidth",listRef.current.scrollWidth)
  //   console.log("right show",(listRef.current.offsetWidth+listRef.current.scrollLeft)<listRef.current.scrollWidth-500)

  // }, []);

  // useEffect(() => {
  //   return () => {
  //     if (riskyCompaniesListRef.current) {
  //       riskyCompaniesListRef.current.removeEventListener('scroll', handleScroll);
  //     }
  //   };
  // }, [riskyCompaniesListRef]);

  // const handleScroll = () => {
  //   if (riskyCompaniesListRef.current) {
  //     const newScrollLeft = riskyCompaniesListRef.current.scrollLeft;
  //     setShowLeftButton(prevState => ({ ...prevState, riskyCompanies: newScrollLeft > 0 }));
  //     setIsScrolling(false);
  //   }
  // };

  const handleScrollNewCompaniesLeft = () => {
    scrollListLeft(newCompaniesListRef);
  };

  const handleScrollNewCompaniesRight = () => {
    scrollListRight(newCompaniesListRef);
  };

  const handleScrollRecentlyUpdatedCompaniesLeft = () => {
    scrollListLeft(recentlyUpdatedCompaniesListRef);
  };

  const handleScrollRecentlyUpdatedCompaniesRight = () => {
    scrollListRight(recentlyUpdatedCompaniesListRef);
  };

  const handleScrollRiskyCompaniesLeft = () => {
    scrollListLeft(riskyCompaniesListRef);
  };

  const handleScrollRiskyCompaniesRight = () => {
    scrollListRight(riskyCompaniesListRef);
  };

  const handleScrollRecentlyVisitedCompaniesLeft = () => {
    scrollListLeft(recentlyVisitedCompaniesListref);
  };

  const handleScrollRecentlyVisitedCompaniesRight = () => {
    scrollListRight(recentlyVisitedCompaniesListref);
  };

  useEffect(() => {
    const subscription = API.graphql(graphqlOperation(onCreateAlert)).subscribe(
      {
        next: async ({ value }) => {
          const newAlert = value.data.onCreateAlert;
          console.log("New Alert:", newAlert);
          if (tenantId === newAlert.tenantId) {
            const list = await getUnreadAlerts();
            const sortedList = [...list].sort(customSort);
            setAlerts(sortedList);
          }
        },
        error: (error) => {
          console.error("Subscription error:", error);
        },
      }
    );

    return () => subscription.unsubscribe();
  }, []);

  const customSort = (a, b) => {
    const typeOrder = [
      "Underwriting Reason",
      "Recommended Limit",
      "Combined Score",
      "Questions Added",
    ];
    const getTypeIndex = (alert) => typeOrder.indexOf(alert.type);
    const typeComparison = getTypeIndex(b) - getTypeIndex(a);
    if (typeComparison !== 0) {
      return typeComparison;
    }
    return a.message.localeCompare(b.message);
  };

  const getSummaryData = async () => {
    try {
      getAlerts();
      const summary = await fetchSummaryJSON(tenantId)
      setSummaryData(summary);
      return { summary };
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getAlerts = async () => {
    try {
      const allAlerts = await getUnreadAlerts();
      setAlerts(allAlerts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAdmin && URLTenant) {
      setTenantId(URLTenant);
    } else if (currentTenant) {
      setTenantId(currentTenant);
    }
  }, [isAdmin, URLTenant, currentTenant]);

  useEffect(() => {
    getSummaryData();
  }, [tenantId]);

  useEffect(() => {
    if (!searchQuery) {
      setSearchResults([]);
      return;
    } else debouncedSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    unsubscribe("create_company");
    subscribe("create_company", (company) => {
      navigate(".", { replace: true });
    });
  });

  // const listStyle={
  //     // overflowX: 'hidden',
  //     display: 'flex',
  //     justifyContent:"center",
  //     gap: '10px',
  // }

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query === "") {
        setSearchResults([]);
      } else {
        const filteredResults = companies.filter((company) =>
          company?.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredResults);
      }
    }, 400),
    [companies]
  );

  const { isFeatureFlag, setFeatureFlag } = useFeatureFlag();
  const {
    show_new_companies_list,
    show_recently_changed_list,
    show_recently_visited_list,
    show_risky_companies_list,
  } = isFeatureFlag?.["Company_Page"] || {};

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <FilterDialog 
        open={filterOpen}
        onClose={handleFilterClose}
        initialFilters={filters}
        onApplyFilters={handleApplyFilters}
        STRING_OPERATORS={STRING_OPERATORS}
        NUMBER_OPERATORS={NUMBER_OPERATORS}
        DATE_OPERATORS={DATE_OPERATORS}
      />

      {isFiltered ? (
        <Box sx={{ mt: 2, mb: 4 }}>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "500",
                color: "#1A2A56",
                marginLeft: "5px",
              }}
            >
              Filtered Results
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#1A2A56",
                fontWeight: "400",
                alignSelf: "flex-end",
                marginBottom: "2px",
              }}
            >
              {filteredCompanies.length} Found
            </Typography>
          </div>
          <List
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {filteredCompanies.filter(company => company).map((company) => (
              <div
                style={{ margin: "0 5px 0 5px" }}
                xs={12}
                sm={6}
                md={4}
                lg={4}
              >
                <CompanyCard
                  key={company?.id || Math.random()}
                  company={company}
                  alerts={alerts}
                  tenantId={tenantId}
                  summaryData={summaryData}
                />
              </div>
            ))}
          </List>
        </Box>
      ) : searchQuery ? (
        <Box sx={{ mt: 2 }}>
          <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "500",
                color: "#1A2A56",
                marginLeft: "5px",
              }}
            >
              Search Results
            </Typography>
            <Typography
              variant="subtitle1"
              style={{
                color: "#1A2A56",
                fontWeight: "400",
                alignSelf: "flex-end",
                marginBottom: "2px",
              }}
            >
              {searchResults.length} Found
            </Typography>
          </div>
          <List
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {searchResults.filter(company => company).map((company) => (
              <div
                style={{ margin: "0 5px 0 5px" }}
                xs={12}
                sm={6}
                md={4}
                lg={4}
              >
                <CompanyCard
                  key={company?.id || Math.random()}
                  company={company}
                  alerts={alerts}
                  tenantId={tenantId}
                  onClick={() => {
                    handleSetCompanyInfo(company);
                    navigate(`/companies/${company.id}`);
                  }}
                  summaryData={summaryData}
                />
              </div>
            ))}
          </List>
        </Box>
      ) : (
        <Box>
          {show_risky_companies_list && (
            <>
              <div style={{ margin: "20px 0 25px 0" }}>
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "500",
                      color: "#1A2A56",
                      marginLeft: "5px",
                    }}
                  >
                    Risky Companies
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#2F3D63",
                      alignSelf: "flex-end",
                      marginBottom: "3px",
                    }}
                    variant="subtitle1"
                  >
                    {riskyCompanies.length} Found
                  </Typography>
                </div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                      ref={riskyCompaniesListRef}
                    >
                      <style>
                        {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
                      </style>
                      <List
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        {riskyCompanies?.filter(company => company).map((company) => (
                          <div
                            style={{ margin: "0 5px 0 0px" }}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                          >
                            <CompanyCard
                              key={company?.id || Math.random()}
                              company={company}
                              tenantId={tenantId}
                              alerts={alerts}
                              adminAction={adminAction}
                              summaryData={summaryData}
                            />
                          </div>
                        ))}
                      </List>
                    </div>
                  </div>
                  {showLeftButton.riskyCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-30px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollRiskyCompaniesLeft}
                    >
                      <img src={LeftIcon} alt="" />
                    </button>
                  ) : null}

                  {riskyCompanies.length > 3 &&
                  showRightButton.riskyCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-20px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollRiskyCompaniesRight}
                    >
                      <img src={RightIcon} alt="" />
                    </button>
                  ) : null}
                </div>
              </div>
              <Divider sx={{ backgroundColor: "#c3daff70" }} />
            </>
          )}
          {show_new_companies_list && (
            <>
              <div style={{ margin: "20px 0 25px 0" }}>
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "500",
                      color: "#1A2A56",
                      marginLeft: "5px",
                    }}
                  >
                    New Companies
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#2F3D63",
                      alignSelf: "flex-end",
                      marginBottom: "3px",
                    }}
                    variant="subtitle1"
                  >
                    {newCompanies.length} Found
                  </Typography>
                </div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                      ref={newCompaniesListRef}
                    >
                      <style>
                        {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
                      </style>
                      <List
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        {newCompanies?.filter(company => company).map((company) => (
                          <div
                            style={{ margin: "0 5px 0 0px" }}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                          >
                            <CompanyCard
                              key={company?.id || Math.random()}
                              company={company}
                              tenantId={tenantId}
                              alerts={alerts}
                              adminAction={adminAction}
                              summaryData={summaryData}
                            />
                          </div>
                        ))}
                      </List>
                    </div>
                  </div>
                  {showLeftButton.newCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-30px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollNewCompaniesLeft}
                    >
                      <img src={LeftIcon} alt="" />
                    </button>
                  ) : null}
                  {newCompanies.length > 3 && showRightButton.newCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-20px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollNewCompaniesRight}
                    >
                      <img src={RightIcon} alt="" />
                    </button>
                  ) : null}
                </div>
              </div>
              <Divider sx={{ backgroundColor: "#c3daff70" }} />
            </>
          )}
          {show_recently_changed_list && (
            <>
              <div style={{ margin: "20px 0 25px 0" }}>
                <div
                  style={{ display: "flex", gap: "20px", alignItems: "center" }}
                >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "500",
                      color: "#1A2A56",
                      marginLeft: "5px",
                    }}
                  >
                    Recently Changed
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#2F3D63",
                      alignSelf: "flex-end",
                      marginBottom: "3px",
                    }}
                    variant="subtitle1"
                  >
                    {recentlyUpdatedCompanies.length} Found
                  </Typography>
                </div>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "row",
                      overflowX: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                      }}
                      ref={recentlyUpdatedCompaniesListRef}
                    >
                      <style>
                        {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
                      </style>
                      <List
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          marginLeft: "5px",
                        }}
                      >
                        {recentlyUpdatedCompanies?.filter(company => company).map((company) => (
                          <div
                            style={{ margin: "0 5px 0 0px" }}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={4}
                          >
                            <CompanyCard
                              key={company?.id || Math.random()}
                              company={company}
                              tenantId={tenantId}
                              alerts={alerts}
                              adminAction={adminAction}
                              summaryData={summaryData}
                            />
                          </div>
                        ))}
                      </List>
                    </div>
                  </div>
                  {showLeftButton.updatedCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "-30px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollRecentlyUpdatedCompaniesLeft}
                    >
                      <img src={LeftIcon} alt="" />
                    </button>
                  ) : null}
                  {recentlyUpdatedCompanies.length > 3 &&
                  showRightButton.updatedCompanies ? (
                    <button
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "-20px",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                      }}
                      onClick={handleScrollRecentlyUpdatedCompaniesRight}
                    >
                      <img src={RightIcon} alt="" />
                    </button>
                  ) : null}
                </div>
              </div>
              <Divider sx={{ backgroundColor: "#c3daff70" }} />
            </>
          )}
          {show_recently_visited_list && (
            <div style={{ margin: "20px 0 25px 0" }}>
              <div
                style={{ display: "flex", gap: "20px", alignItems: "center" }}
              >
                <Typography
                  sx={{
                    fontSize: "26px",
                    fontWeight: "500",
                    color: "#1A2A56",
                    marginLeft: "5px",
                  }}
                >
                  Recently Visited
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#2F3D63",
                    alignSelf: "flex-end",
                    marginBottom: "3px",
                  }}
                  variant="subtitle1"
                >
                  {recentlyVisitedCompanies.length} Found
                </Typography>
              </div>
              <div style={{ position: "relative" }}>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "row",
                    overflowX: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                    }}
                    ref={recentlyVisitedCompaniesListref}
                  >
                    <style>
                      {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
                    </style>
                    <List
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        marginLeft: "5px",
                      }}
                    >
                      {recentlyVisitedCompanies?.filter(company => company).map((company) => (
                        <div
                          style={{ margin: "0 5px 0 0px" }}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={4}
                        >
                          <CompanyCard
                            key={company?.id || Math.random()}
                            company={company}
                            tenantId={tenantId}
                            alerts={alerts}
                            adminAction={adminAction}
                            summaryData={summaryData}
                          />
                        </div>
                      ))}
                    </List>
                  </div>
                </div>
                {showLeftButton.visitedCompanies ? (
                  <button
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "-30px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                    onClick={handleScrollRecentlyVisitedCompaniesLeft}
                  >
                    <img src={LeftIcon} alt="" />
                  </button>
                ) : null}

                {recentlyVisitedCompanies.length > 3 &&
                showRightButton.visitedCompanies ? (
                  <button
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "-20px",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                    onClick={handleScrollRecentlyVisitedCompaniesRight}
                  >
                    <img src={RightIcon} alt="" />
                  </button>
                ) : null}
              </div>
            </div>
          )}
        </Box>
      )}
    </div>
  );
}
