import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
  Grid,
} from "@mui/material";

export default function CompanyFinancialInfo({
  overview,
  balanceSheet,
  incomeStatement,
  linkedInJSON,
  financialData,
}) {
  if (!financialData) return;
  const {
    revenue,
    isAllPRVarsInvalid,
    isAllFinanceInfoVarsInvalid,
    isAllYRVarsInvalid,
    isAllQRVarsInvalid,
    netProfit,
    marketValue,
    operatingIncome,
    netInterestExpense,
    ebitda,
    netIncome,
    totalAssetsLastYear,
    cashAndCashEquivalents,
    totalAssets,
    equity,
    equityLastYear,
    reportDate,
    profitabilityRatios,
    quarterlyData,
    totalLiabilities,
    yearlyData,
    ROE,
    ROA,
    longTermDebt,
    shortTermDebt,
    quaterRows
  } = financialData;
  var dataYear1 = "";
  var dataYear2 = "";
  var dataYear3 = "";
  var dataYear4 = "";
  if (incomeStatement) {
    dataYear1 = incomeStatement?.annualReports[0];
    dataYear2 = incomeStatement?.annualReports[1];
    dataYear3 = incomeStatement?.annualReports[2];
    dataYear4 = incomeStatement?.annualReports[3];
  }
  const ticker = linkedInJSON?.stock?.ticker;
  const tickerMarket = linkedInJSON?.stock?.market;
  const headingStyle = {
      fontSize: "15px",
  };

  const valueStyle = {
    fontSize: "14px",
    fontWeight: "600",
  };
  let funding = {};
  function isValid(value) {
    return (
      value !== null &&
      value !== undefined &&
      value !== "" &&
      !Number.isNaN(value)
    );
  }

  funding = linkedInJSON?.funding;

  const CustomIcon = () => {
    return (
      <Box
        sx={{
          width: 30,
          height: 30,
          backgroundColor: "#FFFFFF",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.29)",
        }}
      >
        <ExpandMoreIcon style={{ color: "#5186EC" }} />
      </Box>
    );
  };
  const formatNumber = (number) => {
    if (
      typeof number === "string" &&
      (number.includes("US $") ||
        number.includes("$") ||
        number.includes("billion") ||
        number.includes("million") ||
        number.includes("thousand"))
    ) {
      return number;
    }
    if (number >= 1e9) {
      return `US $${(number / 1e9).toFixed(2)} billion`;
    } else if (number >= 1e6) {
      return `US $${(number / 1e6).toFixed(2)} million`;
    } else if (number >= 1e3) {
      return `US $${(number / 1e3).toFixed(2)} thousand`;
    } else {
      return `US $${number}`;
    }
  };

  var dataYearQ1 = "";
  var dataYearQ2 = "";
  var dataYearQ3 = "";
  var dataYearQ4 = "";
  //all 3source are from alphavantage
  if (overview && incomeStatement && balanceSheet) {
    dataYearQ1 = incomeStatement?.quarterlyReports[0];
    dataYearQ2 = incomeStatement?.quarterlyReports[1];
    dataYearQ3 = incomeStatement?.quarterlyReports[2];
    dataYearQ4 = incomeStatement?.quarterlyReports[3];
  }


  return (
    <div style={{ padding: "25px" }}>
      <Accordion
        width="90%"
        defaultExpanded
        style={{
          background: "#FAFAFC",
          boxShadow: "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
          borderRadius: "8px",
          opacity: "1",
        }}
      >
        <AccordionSummary
          expandIcon={<CustomIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography
            style={{
              width: "290px",
              height: "24px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#1A2A56",
            }}
          >
            Finance Info
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            rowGap={2}
            display={isAllFinanceInfoVarsInvalid ? "none" : ""}
          >
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={
                revenue.year1 ? "" : "none"
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Revenue{" "}
                  {isValid(revenue.year3) && isValid(revenue.year2)
                    ? "(Last 3 years)"
                    : ""}
                  :
                </Typography>
                <Typography
                  style={{
                    color: "#2F3D63",
                    ...valueStyle,
                  }}
                >
                  {revenue.year3 && revenue.year2
                    ? `${revenue.year1}, ${revenue.year2}, ${revenue.year3}`
                    : revenue.year1}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={
                incomeStatement?.annualReports[0].operatingIncome ? "" : "none"
              }
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Operating Income:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {operatingIncome}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={{totalLiabilities} ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Liabilities:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {totalLiabilities}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={reportDate ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Report Date:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {reportDate}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={totalAssets ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Assets:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {totalAssets}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={totalAssetsLastYear ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Total Assets (Last year):
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {totalAssetsLastYear}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={marketValue ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Market Value:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {marketValue}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={netInterestExpense ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Interest Expense:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {netInterestExpense}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={ebitda ? "block" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  EBITDA:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {ebitda}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={netIncome ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Income:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {netIncome}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={cashAndCashEquivalents ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Cash And Cash Equivalents:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {cashAndCashEquivalents}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} display={netProfit ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Net Profit:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {netProfit}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} display={equity ? "" : "none"}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Equity:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {equity}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={equityLastYear ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Equity (Last year):
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {equityLastYear}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={shortTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Short Term Debt:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {shortTermDebt}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={longTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    ...headingStyle,
                    color: "#777A82",
                  }}
                >
                  Long Term Debt:
                </Typography>
                <Typography
                  style={{
                    ...valueStyle,
                    color: "#2F3D63",
                  }}
                >
                  {longTermDebt}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              display={longTermDebt ? "" : "none"}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography style={{ ...headingStyle, color: "#777A82" }}>
                  Ticker:
                </Typography>
                <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                  {ticker} {tickerMarket ? `(${tickerMarket})` : ""}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box sx={{ display: isAllPRVarsInvalid ? "none" : "block" }}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
                padding: "20px",
              }}
              marginY={4}
            >
              Profitability Ratios
            </Typography>
            <Grid container rowGap={2} ml={3}>
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !(operatingIncome / revenue.year1) ||
                  isNaN(operatingIncome / revenue.year1)
                    ? "none"
                    : "block"
                }
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Operating income/ Revenue
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                  {profitabilityRatios?.operatingIncomeToRevenue}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !(netIncome / revenue.year1) || isNaN(netIncome / revenue.year1)
                    ? "none"
                    : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Net income/ Revenue:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                   {profitabilityRatios?.netIncomeToRevenue}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !ROA || isNaN(ROA) || ROA === "0.00" ? "none" : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    ROA:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {ROA}%
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                display={
                  !ROE || isNaN(ROE) || ROE === "0.00" ? "none" : "block"
                }
              >
                <Box>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    ROE:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {ROE}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: funding ? "block" : "none" }}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
                padding: "20px",
              }}
              marginY={4}
            >
              Funding Information
            </Typography>
            <Grid container rowGap={2} ml={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Total Rounds:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.totalRounds || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Last Round Type:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.lastRound?.type || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Last Round Date:
                  </Typography>
                  <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                    {funding?.lastRound?.date || "N/A"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ ...headingStyle, color: "#777A82" }}>
                    Investors:
                  </Typography>
                  {funding?.investors?.length > 0 ? (
                    funding?.investors.map((investor, index) => (
                      <Typography
                        key={index}
                        style={{ ...valueStyle, color: "#2F3D63" }}
                      >
                        {investor.name} {/* Display only the investor's name */}
                      </Typography>
                    ))
                  ) : (
                    <Typography style={{ ...valueStyle, color: "#2F3D63" }}>
                      N/A
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box padding="25px" display={isAllYRVarsInvalid ? "none" : ""}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
              }}
              marginBottom={2}
            >
              Yearly Review
            </Typography>
            <Box
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F9F9F9",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
              }}
            >
              <TableContainer style={{ borderRadius: "8px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: "#80AAC11A",
                      borderRadius: "8px",
                      opacity: 1,
                      fontFamily: "Rubik , sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        Breakdown
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear1?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear2?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear3?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYear4?.fiscalDateEnding}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {yearlyData?.rows?.map((row) => (
                      <TableRow
                        key={row.name}
                        style={{
                          background: "#FAFAFC",
                          borderRadius: "8px",
                          opacity: 1,
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year1 ? row.year1 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year2 ? row.year2 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year3 ? row.year3 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.year4 ? row.year4 : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
          <Box padding="25px" display={isAllQRVarsInvalid ? "none" : ""}>
            <Typography
              style={{
                width: "290px",
                height: "24px",
                fontSize: "20px",
                fontWeight: "500",
                color: "#1A2A56",
              }}
              marginBottom={2}
            >
              Quaterly Review
            </Typography>
            <Box
              style={{
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                boxShadow: "0px 3px 6px #00000029",
                border: "1px solid #F9F9F9",
                borderRadius: "8px",
                opacity: 1,
                padding: "25px",
              }}
            >
              <TableContainer style={{ borderRadius: "8px" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead
                    style={{
                      background: "#80AAC11A",
                      borderRadius: "8px",
                      opacity: 1,
                      fontFamily: "Rubik , sans-serif",
                      fontSize: "12px",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        Breakdown
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ1?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ2?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ3?.fiscalDateEnding}
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ color: "#1A2A56", borderBottom: "none" }}
                      >
                        {dataYearQ4?.fiscalDateEnding}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quarterlyData?.rows?.map((row) => (
                      <TableRow
                        key={row.name}
                        style={{
                          background: "#FAFAFC",
                          borderRadius: "8px",
                          opacity: 1,
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q1 ? row.Q1 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q2 ? row.Q2 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q3 ? row.Q3 : null}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: "#1A2A56", borderBottom: "none" }}
                        >
                          {row.Q4 ? row.Q4 : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}