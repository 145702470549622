import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";


const prospectsARContext = createContext(undefined);

export const ProspectsARProvider= ({ children }) => {
  const [isProspects, setProspectValue] = useState(() => {
    const savedValue = localStorage.getItem("prospectValue");
    return savedValue? JSON.parse(savedValue) : true;  
  });

  const setProspects=(val)=>{
    setProspectValue(val)
    localStorage.setItem("prospectValue", JSON.stringify(val));
  }
  return (
    <prospectsARContext.Provider value={{ isProspects, setProspects }}>
      {children}
    </prospectsARContext.Provider>
  );
};

export const useProspectsAR = ()=> {
  const context = useContext(prospectsARContext);
  if (!context) {
    throw new Error("prospectAR must be used within a prospectsARProvider");
  }
  return context;
};