import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { getTenantIdAuth0, isAdminAuth0 } from './authUtils';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CompanyPage from './CompanyPage';
import CompaniesTable from './CompaniesTable';
import SummaryPage from "./SummaryPage";
import CustomSourcePage from './CustomSourcePage';
import QuestionsPage, { questionsLoader } from "./QuestionsPage";
import SettingsPage from './SettingsPage';
import ProspectsPage from './ProspectsPage';
import Dashboard from './Dashboard';
import AllSummaryPage from "./AllSummaryPage.js";
import AdminPage from './AdminPage';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import { getCompany, getNewCompanyRecordsByView } from './graphql/queries'
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { LicenseInfo } from '@mui/x-license-pro';
import { Box, Typography, createTheme,ThemeProvider, CssBaseline, ScopedCssBaseline } from '@mui/material';
import FilteredSummaryTable from './FilteredSummaryTable';
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";
import { Auth0Provider,useAuth0 } from '@auth0/auth0-react';
import AWS from 'aws-sdk'
import 'react-toastify/dist/ReactToastify.css';
import getTenantIdFromURL from './utils/getTenantIdFromURL';
import { useDispatch } from 'react-redux';
import { setSubId, fetchAdminStatus, fetchTenantId, } from './app/userInfoSlice';
import { Provider } from 'react-redux';
import {store} from "./app/store.js";
import { useSearchQuery, SearchQueryProvider } from '../src/context/searchContext.js';
import { AddCompanyProvider } from '../src/context/addCompanyModalContext.js';
import { ProspectsARProvider } from './context/prospectsARContext.js';
import { AIChatPage } from './AIChatPage.js';
import { FeatureFlagProvider } from './context/featureFlagContext.js';
import { ARSettingsProvider } from './context/arSettingsContext.js';
import FilteredSummaryTableNew from "./FilteredSummaryTableNew.js"
import { fetchSummaryJSON } from './utils/Helper.js';
import { UserPreferencesProvider } from './context/userPreferencesContext.js';
import { FilterProvider } from './context/filterContext.js';
LicenseInfo.setLicenseKey('4b741527e94a9f1cc1378d5db2a7df3dTz04MDgwNyxFPTE3MzQ2MDU0NTkwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

Amplify.configure(awsExports);
AWS.config.update({ region: 'eu-central-1' });
const dynamoDb = new AWS.DynamoDB.DocumentClient();



const customTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media (min-width: 1200px)': {
            maxWidth: 'none',
          },
        },
      },
    },
  },
});



const companiesLoader = async () => {
  const selectedTenant = getTenantIdFromURL();
  const isAdmin = await isAdminAuth0();
  const tempId = await getTenantIdAuth0();
  const tenantId = isAdmin ? selectedTenant : tempId;

 
  let allCompanies = [];

  try {
    if (!tenantId) {
      throw new Error("Tenant ID could not be determined.");
    }
  
    let nextToken = null;
    do {
      const result = await API.graphql(
        graphqlOperation(getNewCompanyRecordsByView, {
          view: 1,
          limit: 1000,
          filter: {
            tenantId: {
              eq: tenantId
            }
          },
          nextToken: nextToken,
        })
      );
      console.log("result", result);
      const fetchedCompanies = result.data.getNewCompanyRecordsByView.items;
      allCompanies = [...allCompanies, ...fetchedCompanies];
      nextToken = result.data.getNewCompanyRecordsByView.nextToken;
    } while (nextToken);

    let companies = [];
    for (let item of allCompanies) {
      const lastUpdateFormatted = new Date(item.updatedAt);
      item.updatedAtFormatted = lastUpdateFormatted.toLocaleString();
      companies.push(item);
    }

    return { companies: allCompanies };
  } catch (error) {
    console.error('Error fetching companies:', error);
    return { companies: []}
  }
};

const companyLoader = async ({ params }) => {

  const selectedTenant = getTenantIdFromURL();
  const isAdmin = await isAdminAuth0();
  const tenantId= isAdmin?selectedTenant:await getTenantIdAuth0();
  if(!localStorage.getItem("subId")) return <CallbackPage origin="loader" ></CallbackPage>;

  const result = await API.graphql(graphqlOperation(getCompany, {
    tenantId: tenantId,
    id: params.companyId
  }))
  const company = result.data.getCompany;
  return { company };
}

const summaryLoader = async () => {
  try {
    const selectedTenant = getTenantIdFromURL();
    const isAdmin = await isAdminAuth0();
    const currentTenant = await getTenantIdAuth0();
    const tenantId = isAdmin ? selectedTenant : currentTenant;
    if (!localStorage.getItem("subId")) {
      return <CallbackPage origin="loader"></CallbackPage>;
    }
    const summary = await fetchSummaryJSON(tenantId)
    let allCompanies = [];
    let nextToken = null;

    do {
      const result = await API.graphql(
        graphqlOperation(getNewCompanyRecordsByView, {
          view: 1,
          limit: 1000,
          filter: {
            tenantId: {
              eq: tenantId
            }
          },
          nextToken: nextToken,
        })
      );
      console.log("New company record result", result);
      const fetchedCompanies = result.data.getNewCompanyRecordsByView.items;
      allCompanies = [...allCompanies, ...fetchedCompanies];
      nextToken = result.data.getNewCompanyRecordsByView.nextToken;
    } while (nextToken);

    let companies = allCompanies.map((item) => ({
      name: item.name,
      createdAt: item.createdAt,
      createdAtFormatted: new Date(item.createdAt).toLocaleString(),
    }));
    return { summary, companies: allCompanies };
  } catch (error) {
    console.error("Error loading summary:", error);
    return null;
  }
}


const LandingPage = ({ adminAction }) => {
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAdminStatus = async () => {
      const adminStatus = await isAdminAuth0();
      setIsAdminUser(adminStatus);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };
    fetchAdminStatus();
  }, []);


  if (isLoading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5">Fetching Data...</Typography>
      </Box>
    );
  }

  if (!localStorage.getItem("subId")) return <CallbackPage origin="loader" />;
  if (isAdminUser && !adminAction) {
    return <AdminPage />;
  } else if(!isAdminUser || adminAction){
    return <AllSummaryPage adminAction={true} />;
  }
};

export const CallbackPage = ({ origin, login }) => {
  const dispatch = useDispatch();
  const {loginWithRedirect, user} = useAuth0();

  useEffect(() => {
    if (origin === "loader") {
      loginWithRedirect();
    } else if (user?.sub) {
      localStorage.setItem('subId', user.sub);
      dispatch(setSubId(user.sub));
      dispatch(fetchAdminStatus());
      dispatch(fetchTenantId());
      window.location.replace('https://dashboard.crediarc.com/');
      // window.location.replace('http://localhost:3000/');
    } else {
      console.log('User sub not available yet.');
    }
  }, [user, dispatch]);

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <img src="/logo.png" alt="logo" height="120" />
        <Typography variant="h5" mt={2}>
          {login ? (
            <p>Redirecting to login...</p>
          ) : (
            <p>Dashboard Loading...</p>
          )}
        </Typography>
      </Box>
    </div>
  );
};



const routerConfig = [
  {
    path: "/",
    element: <Dashboard/>,
    children: [
      {
        path: "/",
        element: <LandingPage />,
        loader: summaryLoader,
      },
      {
        path: "/arsummary",
        element: <SummaryPage />,
        loader: summaryLoader,
      },
      {
        path: "company/:companyId",
        element: <CompanyPage />,
        loader: companyLoader,
      },
      {
        path: "company/:companyId/custom",
        element: <CustomSourcePage />,
        loader: companyLoader,
      },
      {
        path: "company",
        element:<CompaniesTable />,
        loader: companiesLoader,
      },
      {
        path: "questions",
        element: <QuestionsPage />,
      },
      {
        path: "filteredSummary",
        element: <FilteredSummaryTableNew  />,
      },
      {
        path: "settings",
        element: <SettingsPage openOthers={false}/>,
      },
      {
        path: "settings/AREnableRequest",
        element: <SettingsPage openOthers={true}/>,
      },
      {
        path: "prospects",
        element: <ProspectsPage />,
        loader: summaryLoader,
      },
      {
        path: "aichat",
        element: <AIChatPage/>,
      },
    ],
  },
  {
    path:"/callback",
    element:<CallbackPage/>
  },
  {
    path: "/tenant-dashboard/:tenantIdp",
    element: <Dashboard adminAction={true} />, // done
    children: [
      {
        path: "/tenant-dashboard/:tenantIdp",
        element: <LandingPage adminAction={true} />,  // done
        loader: summaryLoader,
      },
      {
        path: "/tenant-dashboard/:tenantIdp/arsummary/",
        element: <SummaryPage adminAction={true} />,  // done
        loader: summaryLoader,
      },
      {
        path: "/tenant-dashboard/:tenantIdp/company",
        element:<CompaniesTable />,
        loader: companiesLoader
      },
      {
        path: "/tenant-dashboard/:tenantIdp/questions/",
        element: <QuestionsPage adminAction={true} />, //done
      },
      {
        path: "/tenant-dashboard/:tenantIdp/company/:companyId/",
        element: <CompanyPage adminAction={true} />, //done
        loader: companyLoader,
      },
      {
        path: "/tenant-dashboard/:tenantIdp/filteredSummary/",
        element: <FilteredSummaryTableNew adminAction={true} />, 
      },
      {
        path: "/tenant-dashboard/:tenantIdp/company/:companyId/custom/",
        element: <CustomSourcePage adminAction={true} />, //done
        loader: companyLoader,
      },
      {
        path: "/tenant-dashboard/:tenantIdp/prospects/",
        element: <ProspectsPage adminAction={true} />,
        loader: summaryLoader,
      },
      {
        path: "/tenant-dashboard/:tenantIdp/aichat/",
        element: <AIChatPage/>,
      },
    ],
  }
];

const router = createBrowserRouter(routerConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ARSettingsProvider>
      <FeatureFlagProvider>
        <ProspectsARProvider>
          <FilterProvider>
            <SearchQueryProvider>
              <AddCompanyProvider>
              <UserPreferencesProvider>
                <Provider store={store}>
                  <Auth0Provider
                    domain="dev-g8ufszuhs6pup6zf.us.auth0.com"
                    clientId="haSReERgJtRnkRZVKi0LFmZtU5oM6vMt"
                    redirectUri="https://dashboard.crediarc.com/callback"
                    // redirectUri="http://localhost:3000/callback"
                  >
                    <ToastContainer position="bottom-right" />
                    <RouterProvider router={router} />
                  </Auth0Provider>
                </Provider>
              </UserPreferencesProvider>
              </AddCompanyProvider>
            </SearchQueryProvider>
          </FilterProvider>
        </ProspectsARProvider>
      </FeatureFlagProvider>
    </ARSettingsProvider>
  </React.StrictMode>
);
