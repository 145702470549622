import React, { useEffect, useState } from "react";
// import { withAuthenticator } from "@aws-amplify/ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import "@aws-amplify/ui-react/styles.css";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListItemButton from "@mui/material/ListItemButton";
import { useFeatureFlag } from "./context/featureFlagContext";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import BarChartIcon from "@mui/icons-material/BarChart";
import Button from "@mui/material/Button";
import { Logger, API, graphqlOperation } from "aws-amplify";
import awsconfig from "./aws-exports";
import { toast } from "react-toastify";
import { ListItem, Switch, Stack, Fab, Modal } from "@mui/material";
import { getTenantId, getTenantRole } from "./authUtils";
import { Card, CardContent } from "@mui/material";
import { onCreateAlert } from "./graphql/subscriptions";
import AddIcon from "@mui/icons-material/Add";
import {
    bulkUpdateAlerts,
    getUnreadAlerts,
} from "./utils/GraphQLHelper/AlertTable";
import { companiesByName } from "./graphql/queries";
import { createCompany } from "./graphql/mutations";
import ReactGA from "react-ga4";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";
import { CallbackPage } from ".";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import DashboardSVGIcon from "./Assets/Images/dashboard.svg";
import DashboardSVGIconActive from "./Assets/Images/dashboard-active.svg";
import ARSVGIcon from "./Assets/Images/AR.svg";
import ARSVGIconActive from "./Assets/Images/AR-active.svg";
import CompanyCardSVGIcon from "./Assets/Images/companyCards.svg";
import CompanyCardSVGIconActive from "./Assets/Images/companyCards-active.svg";
import ProspectSVGIcon from "./Assets/Images/prospects.svg";
import VendorSVGIcon from "./Assets/Images/vendor.svg";
import AIFinancialSVGIcon from "./Assets/Images/AIFinancial.svg";
import SettingSVGIcon from "./Assets/Images/settings.svg";
import SettingSVGIconActive from "./Assets/Images/settings-active.svg";
import ReportSVGIcon from "./Assets/Images/reports.svg";
import BillingSVGIcon from "./Assets/Images/billing.svg";
import DummyTenantIcon from "./Assets/Images/DummyTenant.png";
import QuestionSVGIcon from "./Assets/Images/questions.svg";
import QuestionSVGIconActive from "./Assets/Images/questions-active.svg";
import rightSide from "./Assets/Images/right-side.svg";
import Rubik from "./Assets/Fonts/Rubik-Regular.ttf";
import { ColorEnum } from "./Assets/Colors/ColorsEnum";
import AWS from "aws-sdk";
import bgArcLight from "./Assets/Images/bg-arc-light.svg";
import logoutIcon from "./Assets/Images/logoutIconNew.svg";
import filterIcon from "./Assets/Images/filterIconNew.svg";
import sortIcon from "./Assets/Images/sortIcon.svg";

import { Menu, MenuItem } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import CreateCompanyModal from "./CreateCompanyModal";

import NotificationDrawer from "./NotificationDrawer/NotificationDrawer";
import { useSearchQuery } from "./context/searchContext";
import SearchBar from "../src/utils/SearchBar";
import CloseIcon from "@mui/icons-material/Close";

import { useProspectsAR } from "./context/prospectsARContext";
import FileUploadModal from "./FileUploadModal";
import { useFilter } from './context/filterContext';

const trackingID = "G-9QZ6JYFX19";
const adminTrackingId = "G-VYW7DRSVZE";

API.configure(awsconfig);

const logger = new Logger("Logger", "INFO");
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    position: "fixed",
    zIndex: theme.zIndex.drawer,
    backgroundColor: "#ECEDF0",
    boxShadow: "none",
    border: "none",
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const bgStyle = {
    width: "100%",
    height: "100%",
    background: `transparent url(${bgArcLight}) 0% 0% no-repeat padding-box`,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 5,
};

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        backgroundColor: ColorEnum.DARK_BLUE,
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));

const rubikFont = {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontDisplay: "swap",
    fontWeight: 500,
    src: `
    local('Rubik'),
    url(${Rubik}) format('truetype')
  `,
};

const defaultTheme = createTheme({
    palette: {
        background: {
            // paper: "#ECEDF0",
            default: "#ECEDF0",
        },
    },
    typography: {
        fontFamily: "Rubik, sans-serif",
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    "@media (min-width: 1200px)": {
                        maxWidth: "none",
                    },
                },
            },
        },
    },
    overrides: {
        MuiCssBaseline: {
            "@global": {
                "@font-face": [rubikFont],
            },
        },
    },
});

function Dashboard({ adminAction }) {
    const {
        user,
        logout,
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
        loginWithRedirect,
    } = useAuth0();

    const [headerText, setHeaderText] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const [alertList, setAlertList] = useState([]);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const [isloading, setIsLoading] = useState(true);
    const [tenantName, setTenantName] = useState("");
    const [isAREnabled, setIsAREnabled] = useState(false);
    const [logo, setLogo] = useState(null);
    const [darkLogo, setDarkLogo] = useState(null);
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
    const { searchQuery, setSearchQuery } = useSearchQuery();
    const selectedTenant = getTenantIdFromURL();
    const tenantIdp = selectedTenant;
    const { isProspects, setProspects } = useProspectsAR();
    const [isModalOpen, setModalOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    if (!localStorage.getItem("subId")) loginWithRedirect();

    // redux variables
    const [tenantId, setTenantId] = useState();

    const isAdmin = useSelector((state) => state.userInfo.isAdmin);
    const currentTenant = useSelector((state) => state.userInfo.tenantId);
    const URLTenant = getTenantIdFromURL();
    const tenantEmail = useSelector((state) => {
        const email = state?.userInfo?.email;
        if (email) {
            const username = email?.split("@")[0];
            return username?.length > 5
                ? `${username?.slice(0, 5)}..`
                : username;
        }
        return null;
    });

    const selectTenantId = () => {
        if (isAdmin) setTenantId(URLTenant);
        else setTenantId(currentTenant);
    };
    const fetchTenantName = async () => {
        const { name, isAREnabled } = await getTenantInfo();
        setIsAREnabled(isAREnabled);
        setTenantName(name);
    };

    useEffect(() => {
        if (isAdmin) {
            ReactGA.initialize(adminTrackingId);
        } else {
            ReactGA.initialize(trackingID);
        }

        ReactGA.send({ hitType: "pageview", page: window.location.pathname });

        fetchTenantName();
    }, []);

    useEffect(() => {
        setSearchQuery();
        if (adminAction) {
            let tenantNames = localStorage.getItem("tenantNames");
            tenantNames = JSON.parse(tenantNames);
            setTenantName(tenantNames[tenantIdp]);
        }
        if (!localStorage.getItem("subId")) loginWithRedirect();

        if (!isAuthenticated && !isLoading) {
            loginWithRedirect();
        }
        if (location.pathname === "/filteredSummary") setProspects(false);
    }, []);
    useEffect(() => {
        selectTenantId();
    }, []);

    // this is causing the Login required errors

    // useEffect(() => {
    //   if (getAccessTokenSilently) {
    //     getAccessTokenSilently().then((data) => {
    //       console.log("🚀 ~ token-:", data)
    //     }
    //     ).catch()
    //   }
    // }, [])

    useEffect(() => {
        const fetchAdminStatus = async () => {
            const adminStatus = isAdmin;
            setIsAdminUser(adminStatus);
            setIsLoading(false);

            if (location.pathname === "/") {
                if (adminStatus) {
                    setHeaderText("Admin Console");
                } else {
                    setHeaderText("Dashboard");
                }
            } else if (location.pathname === "/company") {
                setHeaderText("Company Cards");
            } else if (location.pathname.indexOf("/company") > -1) {
                setHeaderText("Company");
            } else if (location.pathname === "/questions") {
                setHeaderText("Questions");
            } else if (location.pathname === "/settings") {
                setHeaderText("Settings");
            } else if (location.pathname === "/prospects") {
                setHeaderText("Prospects");
            } else if (location.pathname === "/admin") {
                setHeaderText("Admin");
            } else if (location.pathname === "/arsummary") {
                setHeaderText("AR Summary");
            } else if (location.pathname === "/aichat") {
                setHeaderText("AI Financial Chat");
            }
        };

        fetchAdminStatus();
    }, [location]);

    useEffect(() => {
        const subscription = API.graphql(
            graphqlOperation(`subscription {
        onNotifyUserEvent {
          message
          tenantId
          companyName
        }
      }`)
        ).subscribe({
            next: async (data) => {
                console.log("Subscription data received:", data);

                const receivedData = data.value.data.onNotifyUserEvent;

                if (tenantId === receivedData.tenantId) {
                    console.log("tenantId matches!");
                    toast.success(
                        `${receivedData.message} - ${receivedData.companyName}`
                    );
                }
            },
            error: (error) => {
                console.error("Subscription error:", error);
                // Handle subscription errors
            },
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [alertList]);

    // Subscription For Alert
    useEffect(() => {
        const subscription = API.graphql(
            graphqlOperation(onCreateAlert)
        ).subscribe({
            next: async ({ value }) => {
                const newAlert = value.data.onCreateAlert;
                console.log("New Alert:", newAlert);
                if (tenantId === newAlert.tenantId) {
                    const list = await getUnreadAlerts();
                    // const sortedList = [...list].sort(customSort);
                    setAlertList(list);
                }
            },
            error: (error) => {
                console.error("Subscription error:", error);
            },
        });

        return () => subscription.unsubscribe();
    }, [alertList]);

    const customSort = (a, b) => {
        const typeOrder = [
            "Underwriting Reason",
            "Recommended Limit",
            "Combined Score",
            "Questions Added",
        ];
        const getTypeIndex = (alert) => typeOrder.indexOf(alert.type);
        const typeComparison = getTypeIndex(b) - getTypeIndex(a);
        if (typeComparison !== 0) {
            return typeComparison;
        }
        return a.message.localeCompare(b.message);
    };
    const fetchLogo = async () => {
        try {
          const baseUrl =
            "https://crediarc-content01853-production.s3.amazonaws.com/public/logos/";
          const logoUrl = `${baseUrl}${currentTenant}.svg`;
          const darkLogoUrl = `${baseUrl}${currentTenant}-dark.svg`;
  
          let response = await fetch(logoUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch logo: ${response.statusText}`);
          }
          let blob = await response.blob();
          let url = URL.createObjectURL(blob);
          setLogo(url);
  
          response = await fetch(darkLogoUrl);
          if (!response.ok) {
            throw new Error(`Failed to fetch dark logo: ${response.statusText}`);
          }
          blob = await response.blob();
        url = URL.createObjectURL(blob);
        setDarkLogo(url);
        } catch (error) {
        console.log("Error fetching logos", error);
        }
      };

    useEffect(() => {
        const fetchUnreadAlerts = async () => {
            await fetchLogo();
            const list = await getUnreadAlerts();
            // const sortedList = [...list].sort(customSort); // not sorting properly

            setAlertList(list);
        };
        fetchUnreadAlerts();
    }, [tenantId, isAdmin, currentTenant]);

    const handleGoToDashboard = () => {
        if (adminAction) navigate(`/tenant-dashboard/${tenantIdp}`);
        else navigate(`/`);
        return null;
    };
    const handleGoToARSummary = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `AR_Sum-${tenantEmail}_admin_${tenantName}`
                : `AR_Sum-${tenantEmail}_${tenantName}`,
        });
        if (adminAction) navigate(`/tenant-dashboard/${tenantIdp}/arsummary`);
        else navigate(`/arsummary`);
        return null;
    };
    const handleGoToCompanies = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `Companies-${tenantEmail}_admin_${tenantName}`
                : `Companies-${tenantEmail}_${tenantName}`,
        });
        if (adminAction) navigate(`/tenant-dashboard/${tenantIdp}/company`);
        else navigate(`/company`);
        return null;
    };
    const handleGoToAIChatPage = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `AI_Chat-${tenantEmail}_admin_${tenantName}`
                : `AI_Chat-${tenantEmail}_${tenantName}`,
        });
        if (adminAction) navigate(`/tenant-dashboard/${tenantIdp}/aichat`);
        else navigate(`/aichat`);
        return null;
    };
    const handleGoToQuestions = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `Questions-${tenantEmail}_admin_${tenantName}`
                : `Questions-${tenantEmail}_${tenantName}`,
        });
        if (adminAction) navigate(`/tenant-dashboard/${tenantIdp}/questions`);
        else navigate(`/questions`);
        return null;
    };
    const handleGoToSettings = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `Settings-${tenantEmail}_admin_${tenantName}`
                : `Settings-${tenantEmail}_${tenantName}`,
        });

        navigate("/settings");
        return null;
    };
    const handleGoToProspects = () => {
        navigate("/prospects");
        return null;
    };
    const handleGoToAdmin = () => {
        ReactGA.event({
            category: isAdminUser ? "Admin User" : "User",
            action: isAdminUser
                ? `Admin_Console-${tenantEmail}_admin_${tenantName}`
                : `Admin_Console-${tenantEmail}_${tenantName}`,
        });

        // navigate("/admin");
        navigate("/");
        return null;
    };
    const handleLogout = () => {
        logout();
        localStorage.removeItem("subId");
        localStorage.removeItem("prospectValue")
        return <CallbackPage origin={"loader"} />;
    };
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        if (notificationDrawerOpen) {
            handleClose();
        } else {
            setNotificationDrawerOpen(true);
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = async () => {
        setNotificationDrawerOpen(false);
        setAnchorEl(null);
    };
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleMarkAll = async () => {
        setAnchorEl(null);
        const flag = await bulkUpdateAlerts(alertList);
        if (flag) {
            setAlertList([]);
        }
    };

    // const getBackgroundColour = (type) => {
    //   if (type !== null) {
    //     return "#FFFF00";
    //   }
    //   return "white";
    // }

    // const handleAlertClick = async (params) => {
    //   const companyName = JSON.parse(params.companyInfo)?.companyName
    //   if (companyName) {
    //     let result = await API.graphql(
    //       graphqlOperation(companiesByName, {
    //         name: companyName,
    //         limit: 1,
    //         filter: {
    //           tenantId: {
    //             eq: tenantId,
    //           },
    //         },
    //       })
    //     );

    //     const items = result.data.companiesByName.items;
    //     let company = null;
    //     if (items.length === 0) {
    //       try {
    //         result = await API.graphql(
    //           graphqlOperation(createCompany, {
    //             input: {
    //               name: companyName,
    //               tenantId: tenantId,
    //             },
    //           })
    //         );
    //       } catch (error) {
    //         console.log(error);
    //       }
    //       company = result.data.createCompany;
    //     } else {
    //       company = items[0];
    //     }
    //     if(adminAction) window.open(`/tenant-dashboard/${tenantIdp}/company/${company.id}`, "_blank");
    //     else  window.open(`/company/${company.id}`, "_blank");

    //   }
    // }

    const handleMenu = (event) => {
        setAnchorMenu(event.currentTarget);
    };
    const handleCloseMenu = (event) => {
        setAnchorMenu(false);
    };
    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(9px)",
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
                transform: "translateX(12px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor:
                        theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(["width"], {
                duration: 200,
            }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? "rgba(255,255,255,.35)"
                    : "rgba(0,0,0,.25)",
            boxSizing: "border-box",
        },
    }));

    const open1 = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    const handleClosePopup = () => {
        setPopupOpen(false);
    };
    const handleSettingsClick = () => {
        handleClosePopup();
        navigate("/settings/AREnableRequest");
    };
    const handleSwitchClick = (e) => {
        if (isAREnabled) setProspects(e.target.checked);
        else {
            setPopupOpen(true);
        }
    };
    const { isFeatureFlag, setFeatureFlag } = useFeatureFlag();
    const {
        show_al_chat,
        show_billing,
        show_company,
        show_dashboard,
        show_questions,
        show_reports,
        show_settings,
        show_vendor_supply_chain,
    } = isFeatureFlag?.["Left_SlideBar"] || {};

    const { show_add_company_button } = isFeatureFlag?.["Buttons"] || {};

    const [isReportsAvailable, setReportsAvailable] = useState(false);
    const [isVendorPageAvailable, setVendorPageAvailable] = useState(false);
    const { handleFilterOpen } = useFilter();

    if (!localStorage.getItem("subId"))
        return <CallbackPage origin="loader" login={true} />;
    if (isloading) return <></>;
    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                {/* <AppBar position="absolute" open={open}> */}

                <AppBar
                    position="absolute"
                    sx={{
                        width: `calc(100% - ${open ? drawerWidth : 0}px - ${
                            notificationDrawerOpen ? 300 : 0
                        }px)`,
                        right: notificationDrawerOpen ? "300px" : "0px",
                        transition: "width 0.0s",
                    }}
                >
                    <Toolbar
                        sx={{
                            mr: "24px",
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="#2F3D63"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: "36px",
                                ...(open && { display: "none" }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "25px",
                            }}
                        >
                            <Typography
                                sx={{ flexGrow: 1 }}
                                style={{
                                    color: ColorEnum.DARK_BLUE,
                                    fontSize: adminAction? "25px": "38px",
                                    fontFamily: "Rubik, sans-serif",
                                    fontWeight: "500",
                                    padding: "0px 0px",
                                }}
                            >
                                {adminAction
                                    ? `Tenant Dashboard - ${tenantName}`
                                    : headerText}
                            </Typography>
                            {((!adminAction && !isAdmin && location.pathname === "/") || (adminAction && location.pathname === `/tenant-dashboard/${URLTenant}`))? (
                                <Box
                                    sx={{
                                        height:"100%"
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        spacing={1}
                                        alignItems="center"
                                        sx={{ marginRight: "auto" }}
                                    >
                                        <Typography
                                            style={{ color: "#2F3D63" }}
                                        >
                                            Account Receivable
                                        </Typography>
                                        <AntSwitch
                                            checked={isProspects}
                                            inputProps={{
                                                "aria-label": "ant design",
                                            }}
                                            onChange={handleSwitchClick}
                                        />
                                        <Typography
                                            style={{ color: "#2F3D63" }}
                                        >
                                            Prospects
                                        </Typography>
                                    </Stack>
                                </Box>
                            ) : null}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                marginLeft: "auto",
                            }}
                        >
                              {location.pathname==="/company"||(adminAction && location.pathname === `/tenant-dashboard/${URLTenant}/company` )? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: notificationDrawerOpen
                                            ? "column"
                                            : "row",
                                        gap: "0px",
                                    }}
                                >
                                    <Button
                                        onClick={handleFilterOpen}
                                        sx={{
                                            fontSize: "14px",
                                            textTransform: "none",
                                            color: "#5186EC",
                                            fontWeight: "400",
                                            minWidth: "0px",
                                        }}
                                    >
                                        <img src={filterIcon} width="15px" />
                                        Filters
                                    </Button>
                                    {/* <Button
                                        sx={{
                                            fontSize: "14px",
                                            textTransform: "none",
                                            color: "#5186EC",
                                            fontWeight: "400",
                                            minWidth: "0px",
                                        }}
                                    >
                                        <img src={sortIcon} width="15px" />
                                        Sort By
                                    </Button> */}
                                </Box>
                            ) : null}
                            {location.pathname==="/company"||(adminAction && location.pathname === `/tenant-dashboard/${URLTenant}/company` ) ||
                            location.pathname==="/aichat"||(adminAction && location.pathname === `/tenant-dashboard/${URLTenant}/aichat` ) ? (
                                <SearchBar />
                            ) : null}

                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "left",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    color="inherit"
                                    sx={{
                                        width: "45px",
                                        height: "45px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "50%",
                                        boxShadow: "0px 3px 6px #00000029",
                                        margin: "10px",
                                    }}
                                >
                                    {darkLogo ? (
                                        <img
                                            src={darkLogo}
                                            alt=""
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src="/logo-dark.png"
                                            alt=""
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    )}
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorMenu}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    style={{ top: "50px" }}
                                    open={Boolean(anchorMenu)}
                                    onClose={handleCloseMenu}
                                >
                                    {/* <MenuItem onClick={handleCloseMenu} style={{color: "#1A2A56"}}> {user?.name}</MenuItem> */}
                                    <MenuItem onClick={handleLogout}>
                                        Log out
                                    </MenuItem>
                                </Menu>
                            </Box>
                            <Box>
                                {show_add_company_button &&
                                isAREnabled &&
                                isProspects === false ? (
                                    <Button
                                        sx={{
                                            display:
                                                isAdmin &&
                                                location.pathname === "/"
                                                    ? "none"
                                                    : "inline",
                                        }}
                                    >
                                        <Fab
                                            color="primary"
                                            aria-label="add"
                                            sx={{
                                                margin: 0,
                                                width: "45px",
                                                height: "45px",
                                                backgroundColor: "#5186EC",
                                            }}
                                            onClick={openModal}
                                        >
                                            <AddIcon />
                                        </Fab>
                                        <FileUploadModal
                                            open={isModalOpen}
                                            close={closeModal}
                                        />
                                    </Button>
                                ) : (
                                    <Button
                                        sx={{
                                            display:
                                                isAdmin &&
                                                location.pathname === "/"
                                                    ? "none"
                                                    : "inline",
                                        }}
                                    >
                                        <CreateCompanyModal />
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    style={{
                                        height: 45,
                                        width: 50,
                                        minWidth: 50,
                                        backgroundColor: "white",
                                        padding: 0,
                                        marginLeft: "10px",
                                    }}
                                >
                                    {alertList.length > 0 && (
                                        <div
                                            style={{
                                                width: 12,
                                                height: 12,
                                                backgroundColor: "#5186EC",
                                                borderRadius: "50%",
                                                position: "absolute",
                                                top: 10,
                                                right: 11,
                                            }}
                                        />
                                    )}
                                    <img
                                        src={"/alerticon.svg"}
                                        alt="alert"
                                        height={25}
                                        width={25}
                                    />
                                </Button>
                                {/* <Popover
                  id={id}
                  open={open1}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{ padding: "8px" }}
                >
                  <Box width={300} >
                    <Box>
                      <List>
                        <ListItem>
                          <Grid container spacing={5} alignItems="center">
                            <Grid item>
                              <Typography variant="h6">
                                Notifications
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Button
                                sx={{ fontSize: 12 }}
                                color="primary"
                                variant="contained"
                                size="small"
                                disabled={alertList.length === 0 ? true : false}
                                onClick={handleMarkAll}
                              >
                                Mark As Read
                              </Button>
                            </Grid>
                          </Grid>
                        </ListItem>
                        <Divider />
                      </List>
                    </Box>
                    <Box overflow="auto" maxHeight={500}>
                      <List>
                        {alertList.length > 0 ? (
                          alertList.map((notification, index) => (
                            <Box key={index} sx={{ cursor: "pointer" }} onClick={() => handleAlertClick(notification)}>
                              <ListItem dense={true} style={{ backgroundColor: getBackgroundColour(notification.type) }}>
                                {notification.type !== null && (
                                  <ListSubheader>
                                    Status Change
                                  </ListSubheader>
                                )}
                                {notification.type === null && (
                                  <ListSubheader>
                                    Process Completed
                                  </ListSubheader>
                                )}
                              </ListItem>
                              <ListItem dense={true} style={{ backgroundColor: getBackgroundColour(notification.type) }}>
                                <ListItemText primaryTypographyProps={{ fontSize: 14 }}
                                  secondary={notification.message}
                                />
                              </ListItem>
                              <ListItem dense={true} style={{ backgroundColor: getBackgroundColour(notification.type) }}>
                                <ListItemText primaryTypographyProps={{ fontSize: 14 }}
                                  secondaryTypographyProps={{ fontSize: 12 }}
                                  secondary={new Date(
                                    notification.createdAt
                                  ).toLocaleString()}
                                />
                              </ListItem>
                              {index < alertList.length - 1 && <Divider />}
                            </Box>
                          ))
                        ) : (
                          <ListItem>
                            <Typography variant="caption" color={"grey"}>
                              Empty
                            </Typography>
                          </ListItem>
                        )}
                      </List>
                    </Box>
                  </Box>
                </Popover> */}
                            </Box>
                        </Box>
                    </Toolbar>
                    <Modal
                        open={popupOpen}
                        onClose={handleClosePopup}
                        aria-labelledby="ar-disabled-popup-title"
                        aria-describedby="ar-disabled-popup-description"
                    >
                        <Box
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                width: "400px",
                                transform: "translate(-50%, -50%)",
                                bgcolor: "background.paper",
                                borderRadius: "20px",
                                boxShadow:
                                    "inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029",
                                padding: "20px",
                                paddingTop: "10px",
                                paddingLeft: "45px",
                            }}
                        >
                            <Typography
                                id="ar-disabled-popup-title"
                                variant="h6"
                                component="h2"
                                sx={{ color: "#2F3D63" }}
                            >
                                Accounts Receivable Not Enabled
                            </Typography>
                            <Typography
                                id="ar-disabled-popup-description"
                                sx={{ mt: 2, color: "#3d4e7a" }}
                            >
                                You currently don't have Accounts Receivable
                                enabled. To enable it, please click on the link:
                                <span style={{ marginLeft: "10px" }}>
                                    <a // Replace with the actual URL or route for settings
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default link behavior
                                            handleSettingsClick(); // Call the handler function if needed
                                        }}
                                        style={{
                                            color: "#5186EC",
                                            textDecoration: "underline",
                                            wordWrap: "break-word",
                                            cursor: "pointer",
                                        }}
                                    >
                                        go to settings
                                    </a>
                                </span>
                            </Typography>

                            <IconButton
                                sx={{ position: "absolute", top: 5, left: 5 }}
                                onClick={handleClosePopup}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Modal>
                </AppBar>
                <Drawer
                    variant="permanent"
                    open={open}
                    sx={{
                        "& .MuiDrawer-paper": {
                            position: "relative",
                            whiteSpace: "nowrap",
                            width: drawerWidth,
                            transition: "width 0.3s",
                            boxSizing: "border-box",
                            overflowX: "hidden",
                            ...(open ? { width: drawerWidth } : { width: 0 }),
                        },
                    }}
                >
                    <Toolbar
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            px: [1],
                        }}
                    >
                        <img
                            src="/logo.svg"
                            alt="logo"
                            style={{ paddingLeft: 50 }}
                        />
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon
                                sx={{
                                    color: ColorEnum.TEXT_WHITE,
                                    fontSize: "16px",
                                }}
                            />
                        </IconButton>
                    </Toolbar>
                    <div
                        style={{
                            height: "calc( 100vh - 64px )",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#2F3D63",
                        }}
                    >
                        {(!isAdminUser || adminAction) && (
                            <List
                                component="nav"
                                style={{ flex: 1, overflow: "auto" }}
                            >
                                {show_dashboard && (
                                    <ListItemButton>
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            {location.pathname === "/" ? (
                                                <img
                                                    src={DashboardSVGIconActive}
                                                    alt="Icon"
                                                />
                                            ) : (
                                                <img
                                                    src={DashboardSVGIcon}
                                                    alt="Icon"
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            sx={{
                                                color: ColorEnum.TEXT_WHITE,
                                                fontSize: "16px",
                                            }}
                                            primary="Dashboard"
                                            onClick={() =>
                                                handleGoToDashboard()
                                            }
                                        />
                                        {location.pathname === "/" && (
                                            <img src={rightSide} alt="Icon" />
                                        )}
                                    </ListItemButton>
                                )}

                                {location.pathname === "/" && (
                                    <Divider
                                        sx={{
                                            height: "1px",
                                            backgroundColor:
                                                ColorEnum.PRIMARY_BLUE,
                                            mx: 2,
                                        }}
                                    />
                                )}

                                {show_vendor_supply_chain && (
                                    <Tooltip title="Disabled" arrow>
                                        <ListItemButton sx={{ opacity: 0.5 }}>
                                            <ListItemIcon
                                                sx={{ minWidth: "35px" }}
                                            >
                                                <img
                                                    src={VendorSVGIcon}
                                                    alt="Icon"
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontSize: 14,
                                                }}
                                                sx={{
                                                    color: ColorEnum.TEXT_WHITE,
                                                    fontSize: "16px",
                                                }}
                                                primary="Vendor/Supply Chain"
                                            />
                                        </ListItemButton>
                                    </Tooltip>
                                )}

                                {show_company && (
                                    <ListItemButton>
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            {location.pathname ===
                                            "/company" ? (
                                                <img
                                                    src={
                                                        CompanyCardSVGIconActive
                                                    }
                                                    alt="Icon"
                                                />
                                            ) : (
                                                <img
                                                    src={CompanyCardSVGIcon}
                                                    alt="Icon"
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            sx={{
                                                color: ColorEnum.TEXT_WHITE,
                                                fontSize: "16px",
                                            }}
                                            primary="Companies"
                                            onClick={() =>
                                                handleGoToCompanies()
                                            }
                                        />
                                        {location.pathname === "/company" && (
                                            <img src={rightSide} alt="Icon" />
                                        )}
                                    </ListItemButton>
                                )}

                                {location.pathname === "/company" && (
                                    <Divider
                                        sx={{
                                            height: "1px",
                                            backgroundColor:
                                                ColorEnum.PRIMARY_BLUE,
                                            mx: 2,
                                        }}
                                    />
                                )}

                                {show_al_chat && (
                                    <ListItemButton
                                        onClick={() => handleGoToAIChatPage()}
                                    >
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            <img
                                                src={AIFinancialSVGIcon}
                                                alt="Icon"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            sx={{
                                                color: ColorEnum.TEXT_WHITE,
                                                fontSize: "16px",
                                            }}
                                            primary="AI Financial Chat"
                                        />
                                    </ListItemButton>
                                )}

                                <Divider
                                    sx={{
                                        height: "1px",
                                        backgroundColor: "#3753A0",
                                        m: 2,
                                    }}
                                />

                                {show_questions && (
                                    <ListItemButton>
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            {location.pathname ===
                                            "/questions" ? (
                                                <img
                                                    src={QuestionSVGIconActive}
                                                    alt="Icon"
                                                />
                                            ) : (
                                                <img
                                                    src={QuestionSVGIcon}
                                                    alt="Icon"
                                                />
                                            )}
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            sx={{
                                                color: ColorEnum.TEXT_WHITE,
                                                fontSize: "16px",
                                            }}
                                            primary="Questions"
                                            onClick={() =>
                                                handleGoToQuestions()
                                            }
                                        />
                                        {location.pathname === "/questions" && (
                                            <img src={rightSide} alt="Icon" />
                                        )}
                                    </ListItemButton>
                                )}

                                {location.pathname === "/questions" && (
                                    <Divider
                                        sx={{
                                            height: "1px",
                                            backgroundColor:
                                                ColorEnum.PRIMARY_BLUE,
                                            mx: 2,
                                        }}
                                    />
                                )}

                                {!adminAction && (
                                    <>
                                        {show_reports && (
                                            <Tooltip title="Coming soon" arrow>
                                                <ListItemButton
                                                    sx={{ opacity: 0.5 }}
                                                >
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: "35px",
                                                        }}
                                                    >
                                                        <img
                                                            src={ReportSVGIcon}
                                                            alt="Icon"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primaryTypographyProps={{
                                                            fontSize: 14,
                                                        }}
                                                        sx={{
                                                            color: ColorEnum.TEXT_WHITE,
                                                            fontSize: "16px",
                                                        }}
                                                        primary="Reports"
                                                    />
                                                </ListItemButton>
                                            </Tooltip>
                                        )}

                                        {location.pathname === "/reports" && (
                                            <Divider
                                                sx={{
                                                    height: "1px",
                                                    backgroundColor:
                                                        ColorEnum.PRIMARY_BLUE,
                                                    mx: 2,
                                                }}
                                            />
                                        )}

                                        {show_billing && (
                                            <ListItemButton>
                                                <ListItemIcon
                                                    sx={{ minWidth: "35px" }}
                                                >
                                                    <img
                                                        src={BillingSVGIcon}
                                                        alt="Icon"
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        fontSize: 14,
                                                    }}
                                                    sx={{
                                                        color: ColorEnum.TEXT_WHITE,
                                                        fontSize: "16px",
                                                    }}
                                                    primary="Billing"
                                                />
                                            </ListItemButton>
                                        )}

                                        {location.pathname === "/billing" ? (
                                            <Divider
                                                sx={{
                                                    height: "1px",
                                                    backgroundColor:
                                                        ColorEnum.PRIMARY_BLUE,
                                                    mx: 2,
                                                }}
                                            />
                                        ) : (
                                            <Divider
                                                sx={{
                                                    height: "1px",
                                                    backgroundColor: "#3753A0",
                                                    m: 2,
                                                }}
                                            />
                                        )}

                                        {show_settings && (
                                            <ListItemButton
                                                onClick={() =>
                                                    handleGoToSettings()
                                                }
                                                dense
                                            >
                                                <ListItemIcon
                                                    sx={{ minWidth: "35px" }}
                                                >
                                                    {location.pathname ===
                                                    "/settings" ? (
                                                        <img
                                                            src={
                                                                SettingSVGIconActive
                                                            }
                                                            alt="Icon"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={SettingSVGIcon}
                                                            alt="Icon"
                                                        />
                                                    )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        fontSize: 14,
                                                    }}
                                                    sx={{
                                                        color: ColorEnum.TEXT_WHITE,
                                                        fontSize: "16px",
                                                    }}
                                                    primary="Settings"
                                                />
                                                {location.pathname ===
                                                    "/settings" && (
                                                    <img
                                                        src={rightSide}
                                                        alt="Icon"
                                                    />
                                                )}
                                            </ListItemButton>
                                        )}

                                        {location.pathname === "/settings" && (
                                            <Divider
                                                sx={{
                                                    height: "1px",
                                                    backgroundColor:
                                                        ColorEnum.PRIMARY_BLUE,
                                                    mx: 2,
                                                }}
                                            />
                                        )}
                                    </>
                                )}
                            </List>
                        )}
                        {isAdminUser && !adminAction && (
                            <List component="nav">
                                <ListItemButton>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        <BarChartIcon sx={{ color: "#fff" }} />
                                    </ListItemIcon>
                                    <a href="/">
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            primary="Admin Console"
                                            onClick={() => handleGoToAdmin()}
                                            sx={{ color: "#fff" }}
                                        />
                                    </a>
                                </ListItemButton>
                            </List>
                        )}
                        <Card
                            sx={{
                                mx: 2,
                                my: 2,
                                backgroundColor: "#3A538C",
                                maxHeight: 160,
                                position: "relative",
                            }}
                        >
                            <div
                                style={{
                                    ...bgStyle,
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0,
                                }}
                            ></div>
                            <CardContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        height: "60px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    {logo ? (
                                        <img
                                            src={logo}
                                            alt=""
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain",
                                                marginRight: "auto",
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src="/logo.svg"
                                            alt=""
                                            style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                                objectFit: "contain",
                                            }}
                                        />
                                    )}
                                </div>
                                <List>
                                    <Typography color={ColorEnum.TEXT_WHITE}>
                                        Hello {user?.name}
                                    </Typography>
                                    <ListItem
                                        sx={{
                                            right: 15,
                                            cursor: "pointer",
                                            zIndex: "5",
                                        }}
                                        onClick={handleLogout}
                                    >
                                        <ListItemIcon sx={{ minWidth: "35px" }}>
                                            <img
                                                src={logoutIcon}
                                                width="22"
                                                height="22"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primaryTypographyProps={{
                                                fontSize: 14,
                                            }}
                                            sx={{
                                                color: ColorEnum.TEXT_WHITE,
                                                cursor: "pointer",
                                            }}
                                            primary="Logout"
                                            onClick={handleLogout}
                                        />
                                    </ListItem>
                                </List>
                            </CardContent>
                        </Card>
                    </div>
                </Drawer>
                <Box
                    component="main"
                    // sx={{
                    //   backgroundColor: (theme) =>
                    //     theme.palette.mode === "light"
                    //       ? theme.palette.grey[100]
                    //       : theme.palette.grey[900],
                    //   flexGrow: 1,
                    //   height: "100vh",
                    //   overflow: "auto",
                    // }}
                    sx={{
                        backgroundColor: "#ECEDF0", // Set the desired background color
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Outlet></Outlet>
                    </Container>
                </Box>
                <NotificationDrawer
                    open={notificationDrawerOpen}
                    handleMark={() => handleMarkAll()}
                    close={() => setNotificationDrawerOpen(false)}
                    data={alertList}
                />
            </Box>
        </ThemeProvider>
    );
}

export default Dashboard;
