/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://ldpfchh4rnby5nmhjjbmtah6ym.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6ax43lf3ibg2tbm6ioz24wyqje",
    "aws_cloud_logic_custom": [
        {
            "name": "yfinanceapi",
            "endpoint": "https://8gt2w3pfze.execute-api.eu-central-1.amazonaws.com",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:a58cf8d5-ad7c-48fa-b0bc-98601792d0ba",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_7v88gMbqW",
    "aws_user_pools_web_client_id": "371attlnkl6do55am9r1o7hc6l",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "crediarc-content01853-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
