import React, { useState, useEffect , useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Input,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import { createAPIMapping, createChatFileCollection , deleteChatFileCollection, updateAPIMapping } from "./graphql/mutations";
import { listAPIMappings, listChatFileCollections } from "./graphql/queries";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import dayjs from "dayjs";
import { getTenantInfo } from "./utils/GraphQLHelper/GetTenantInfo";



const ChatFile = ({ company }) => {
  const [file, setFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [currentFileId, setCurrentFileId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedExtensions = [
      "c", "cpp", "css", "csv", "docx", "gif", "html", "java", "jpeg", "jpg",
      "js", "json", "md", "pdf", "php", "png", "pptx", "py", "rb", "tar", "tex",
      "ts", "txt", "webp", "xlsx", "xml", "zip"
    ];

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage("This file is not allowed.");
    } else {
      setErrorMessage("");
      setFile(file);
    }
  };

  const uploadFile = async () => {
    try {
      if (!file) return;
      const bucket = "chat-files-production";

      const url = `https://${bucket}.s3.amazonaws.com/${file.name}`;
  
      const data = await fetch(url, {
        method: 'PUT',
        headers: {
          'x-amz-acl': 'public-read-write', 
          'Content-Type': file.type || 'application/octet-stream', 
        },
        body: file,
      });
      console.log(data, "Uploaded File Data");
      
      if (data.Location) {
        uploadFileToHeyBrain(data.Location);
      }

      setFile(null);
      setOpenModal(false);
      fetchFileList();
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const deleteFile = async (file) => {
    deleteFileInfo(file.fileId);
  };

  const fetchFileList = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(listChatFileCollections, {
          limit: 1000,
          filter: {
            tenantId: {
              eq: company.tenantId,
            },
            companyId: {
              eq: company.id,
            },
          },
        })
      );

      console.log(result.data.listChatFileCollections.items, "File List");
      const list = result.data.listChatFileCollections.items;
      setFileList(list);
    } catch (error) {
      console.error("Error fetching file list: ", error);
    }
  };

  const addFileInfo = async (fileId, filePath) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createChatFileCollection, {
          input: {
            tenantId: company.tenantId,
            companyId: company.id,
            fileId: fileId,
            fileName: file.name,
            fileS3Path: filePath,
          },
        })
      );

      console.log(result);
      toast.success("File Uploaded Successfully!");
      fetchFileList();
    } catch (error) {
      console.log(error);
    }
  };

  const mapAPItoDB = async (tenantId, isPaid, origin, endpoint) => {
    let result = await API.graphql(
      graphqlOperation(listAPIMappings,{tenantId})
    );
    const apiMappings = result.data.listAPIMappings.items;
    console.log(`APIMappings for ${tenantId}`, apiMappings);
    const existingEntry = apiMappings.find(
    (entry) => (entry.endpoint === endpoint && entry.origin === origin)
  );
    if (existingEntry) {
      console.log(`Mapping for endpoint ${endpoint} exists`);
      await API.graphql(
        graphqlOperation(updateAPIMapping, {
          input: {
            id: existingEntry.id,
            tenantId: tenantId,
            totalCalls: existingEntry.totalCalls + 1,
            isPaid,
          },
        }))
    } else {
      console.log(`Mapping for endpoint ${endpoint} doesn't exists`);
      await API.graphql(
        graphqlOperation(createAPIMapping, {
          input: {
            tenantId: tenantId,
            endpoint: endpoint,
            totalCalls: 1,
            origin: origin,
            isPaid,
          },
        })
      );
    }
  };


  const deleteFileInfo = async (fileId) => {
    try {
      const result = await API.graphql(
        graphqlOperation(deleteChatFileCollection, {
          input: {
            fileId: fileId,
          },
        })
      );
      console.log(result);
      toast.success("File Deleted Successfully!");
      fetchFileList();
    } catch (error) {
      console.log(error);
    }
  };



  const uploadFileToHeyBrain = async (filePath) => {
    const allowedExtensions = [
      "c", "cpp", "css", "csv", "docx", "gif", "html", "java", "jpeg", "jpg",
      "js", "json", "md", "pdf", "php", "png", "pptx", "py", "rb", "tar", "tex",
      "ts", "txt", "webp", "xlsx", "xml", "zip"
    ];    
    const url = "https://api.nlpgraph.com/stage/api/brain/get_url_data";
    const apiKey = "325176a05bde66cf846db4bc164a0b83";
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      setErrorMessage("This file is not allowed.");
      return;
    }

    try {
      const payload = {
        title: file.name,
        file_url: filePath,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": apiKey,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data from the API");
      }
      const {isPaid} = await  getTenantInfo();
      await mapAPItoDB(company?.tenantId,isPaid,"Dashboard_Company_Page","Hey_Brain_Get_URL_Data");


      const responseData = await response.json();
      console.log(responseData, "HeyBrain Response!"); // Handle the response data here
      const fileId = responseData.response.__file_id;

      if (fileId) {
        addFileInfo(fileId, filePath);
        await mapAPItoDB(company?.tenantId,isPaid,"Dashboard_Company_Page","Hey_Brain_Chat");
      }

      setErrorMessage("");
    } catch (error) {
      console.error("Error", error);
    }
  };

  const handleFileClick = (fileInfo) => {
    console.log(fileInfo, "5050");
    setCurrentFileId(fileInfo.fileId);
    setChatOpen(true);
  };

  useEffect(() => {
    fetchFileList();
  }, []);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const textStyle = {
    width: '264px',
    fontSize: '14px',
    fontWeight: '200',
    color: '#777A82',
  }
  return (
    <div style={{ padding: '25px'}}>
      <Box 
        style= {{
          height: '180px',
          background: '#FAFAFC 0% 0% no-repeat padding-box',
          boxShadow: 'inset 0px 1px 3px #0000001A, 0px 3px 6px #00000029',
          borderRadius: '8px',
          opacity: 1,
          padding: '25px'
        }}
      >
        <Typography style={{
              width: "290px",
              height: "24px",
              fontSize: "20px",
              fontWeight: "500",
              color: "#1A2A56",
          }}>
          Upload File
        </Typography>
          <Typography
            onClick = {handleButtonClick}
            style={{ 
              paddingTop: '20px',
              textTransform : 'none',
              color : "#5186EC",
              fontSize: '16px',
              fontWeight: '200',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Select a file to upload
          </Typography>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <div style={{ display: "flex" , flexDirection: "row" , borderBottom: '1px solid  #777A82', width: '320px' , paddingBottom: '10px' , paddingTop: '25px'  }}>
          {file === null && <Typography style={textStyle}>
            No file selected!
            </Typography>}
          {file && <Typography style={textStyle}>
            {file.name}
          </Typography>}
          {errorMessage && 
          <Typography style = {{ color: 'red'}}>{errorMessage}</Typography>
          }
          <Button 
            onClick={uploadFile} 
            style={{
              width: '87px',
              height: '30px',
              background: '#5186EC 0% 0% no-repeat padding-box',
              borderRadius: '8px',
              opacity: 1,
              color: 'white',
              fontWeight: '200',
              textTransform: 'none',
            }}    
          >
            Add File
          </Button>
          </div>
     </Box>
     <div>
     <List>
        {fileList.map((file, index) => (
                <div key={index} style={{ width: '40%' , justifyContent: 'center'}}>
                  <ListItem>
                    <ListItemText
                      onClick={() => handleFileClick(file)}
                      primary={file.fileName}
                      style={{
                        cursor: 'pointer',
                        color: '#777A82'
                      }}
                    />
                    <img src={'/Deleteicon.svg'} style={{height:'15px'}} onClick={() => deleteFile(file)} alt="delete icon"/>
                  </ListItem>
                </div>
              ))}
            </List>
     </div>
      <Modal
        open={chatOpen}
        // onClose={() => setChatOpen(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "80%", display: "flex" }}>
          {/* <Button sx={{ color: 'red', textAlign: 'right' }} onClick={() => setChatOpen(false)}>Close</Button> */}
          <iframe
            title="Heybrain Chatbot"
            src={`https://dty0xa7tzq24v.cloudfront.net?api_key=325176a05bde66cf846db4bc164a0b83&fileId=${currentFileId}`}
            style={{
              width: "100%",
              height: "90vh", // Adjust height as needed
              border: "none",
              borderRadius: 5,
            }}
          />
          <Box sx={{ position: "absolute", top: 20, right: 20 }}>
            <Button variant="contained" onClick={() => setChatOpen(false)}>
              <CloseIcon sx={{ fontSize: "40", color: "black" }} />
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChatFile;
