import { Typography } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { API, graphqlOperation } from "aws-amplify";
import React, { useState,useEffect } from "react";
import { createCompany } from "./graphql/mutations";
import { companiesByName } from "./graphql/queries";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";

const FilteredSummaryTable = ({adminAction}) => {
  const storedData = localStorage.getItem("filteredSummary");
  const segmentName = JSON.parse(localStorage.getItem("segmentName"));
  const rangeTypeName = JSON.parse(localStorage.getItem("rangeTypeName"));
  const metricName = JSON.parse(localStorage.getItem("metricName"));
  const tableName = `${metricName} : ${segmentName} : ${rangeTypeName}`;
  const [tenantId,setTenantId] = useState()
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }

  useEffect(()=>{
    selectTenantId();
  },[])

  let filteredSummary = [];
  if (storedData) {
    filteredSummary = JSON.parse(storedData);
  }

  const CreateColumnHeader = (headerTitle) => {
    return (
      <Typography variant="caption" fontWeight="bold">
        {headerTitle}
      </Typography>
    );
  };

  function getUniqueKeys(arrayOfObjects) {
    let uniqueKeys = new Set();
    arrayOfObjects?.forEach((obj) => {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          uniqueKeys.add(key);
        }
      }
    });
    return Array.from(uniqueKeys);
  }

  const columnDefinition = [];
  const columns = getUniqueKeys(filteredSummary).filter(
    (column) => column !== "" && column !== "id"
  );

  columns.forEach((column) => {
    const definition = {
      field: column,
      headerName: column,
      width: 120,
      renderHeader: (params) => {
        return CreateColumnHeader(params.colDef.headerName);
      },
    };

    if (typeof filteredSummary[0][column] === "number") {
      definition.type = "number";
    }

    if (column === "Company Name") {
      definition.renderCell = (params) => {
        return (
          <div style={{ cursor: "pointer" }}>{params?.row["Company Name"]}</div>
        );
      };
    }

    columnDefinition.push(definition);
  });

  const handleCellClick = async (params) => {
    if (params.field === "Company Name") {
      let nextToken = null;
      let items = []
      let result;
      do {
        result = await API.graphql(
          graphqlOperation(companiesByName, {
            name: params.row["Company Name"],
            limit: 1,
            filter: {
              tenantId: {
                eq: tenantId,
              },
            },
            nextToken: nextToken,
          })
        );

        items = result.data.companiesByName.items;
        nextToken = result.data.companiesByName.nextToken;

        if (items.length > 0) {
          break;
        }
      } while (nextToken != null);

      let company = null;
      if (items.length > 0) {
        company = items[0];
      } else {
        return;
      }
      if(adminAction) window.open(`/tenant-dashboard/${tenantId}/company/${company.id}`, "_blank");
      else window.open(`/company/${company.id}`, "_blank");
    }
  };

  return (
    <>
      <div style={{ width: "100%", height: "85vh" }}>
        <Typography variant="h5" mb={5}>
          {tableName}
        </Typography>
        <DataGridPremium
          rows={filteredSummary}
          columns={columnDefinition}
          editMode="row"
          onCellClick={handleCellClick}
          // autosizeOnMount={true}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[5, 10, 50]}
        />
      </div>
    </>
  );
};

export default FilteredSummaryTable;