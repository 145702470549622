import React, { useState, useEffect, useMemo } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';

const FilterRow = React.memo(({ 
  label, 
  filterKey, 
  operators, 
  type = 'text',
  value,
  operator,
  onValueChange,
  onOperatorChange,
  onClear
}) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const debouncedUpdate = useMemo(
    () => debounce((newValue) => {
      onValueChange(filterKey, newValue);
    }, 300),
    [filterKey, onValueChange]
  );

  const handleLocalChange = (e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedUpdate(newValue);
  };

  return (
    <div style={{ 
      display: 'grid', 
      gridTemplateColumns: '200px 150px 1fr auto', 
      gap: '10px',
      alignItems: 'center'
    }}>
      <div style={{ 
        color: '#666', 
        fontSize: '14px',
        fontWeight: '500'
      }}>
        {label}
      </div>
      <FormControl size="small" fullWidth>
        <Select
          value={operator}
          onChange={(e) => onOperatorChange(filterKey, e.target.value)}
          size="small"
        >
          {Object.entries(operators).map(([key, value]) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        name={filterKey}
        value={localValue}
        onChange={handleLocalChange}
        type={type}
        size="small"
        fullWidth
      />
      {localValue && (
        <IconButton 
          onClick={() => {
            setLocalValue('');
            onClear(filterKey);
          }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
});

const FilterDialog = ({ 
  open, 
  onClose, 
  initialFilters, 
  onApplyFilters,
  STRING_OPERATORS, 
  NUMBER_OPERATORS,
  DATE_OPERATORS 
}) => {
  const defaultValues = {
    companyName: {
      value: '',
      operator: STRING_OPERATORS.CONTAINS
    },
    requestedLimit: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    recommendedLimit: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    arcScore: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    rationale: {
      value: '',
      operator: STRING_OPERATORS.CONTAINS
    },
    createdAt: {
      value: '',
      operator: DATE_OPERATORS.EQUALS
    },
    paymentIndex: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    },
    arInArrear: {
      value: '',
      operator: NUMBER_OPERATORS.EQUALS
    }
  };

  const [formValues, setFormValues] = useState({...defaultValues, ...initialFilters});
  
  useEffect(() => {
    if (open) {
      setFormValues({...defaultValues, ...initialFilters});
    }
  }, [open, initialFilters]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onApplyFilters(formValues);
    onClose();
  };

  const handleValueChange = (filterKey, value) => {
    setFormValues(prev => ({
      ...prev,
      [filterKey]: {
        ...prev[filterKey],
        value: value
      }
    }));
  };

  const handleOperatorChange = (filterKey, value) => {
    setFormValues(prev => ({
      ...prev,
      [filterKey]: {
        ...prev[filterKey],
        operator: value
      }
    }));
  };

  const handleClearFilter = (filterKey) => {
    setFormValues(prev => ({
      ...prev,
      [filterKey]: {
        ...prev[filterKey],
        value: ''
      }
    }));
  };

  const handleClearAll = () => {
    const clearedValues = Object.keys(formValues).reduce((acc, key) => ({
      ...acc,
      [key]: {
        ...formValues[key],
        value: ''
      }
    }), {});
    setFormValues(clearedValues);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md" 
      PaperProps={{
        sx: { maxHeight: '80vh' , borderRadius:"20px", width:"60%"}
      }}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid #e0e0e0',
          mb: 2
        }}>

            <Typography variant="h6" component="span">
              Filter Companies
            </Typography>

          <IconButton
            aria-label="close"
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '16px'
          }}>
            <FilterRow 
              label="Company Name"
              filterKey="companyName"
              operators={STRING_OPERATORS}
              value={formValues.companyName.value}
              operator={formValues.companyName.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Requested Limit"
              filterKey="requestedLimit"
              operators={NUMBER_OPERATORS}
              type="number"
              value={formValues.requestedLimit.value}
              operator={formValues.requestedLimit.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Recommended Limit"
              filterKey="recommendedLimit"
              operators={NUMBER_OPERATORS}
              type="number"
              value={formValues.recommendedLimit.value}
              operator={formValues.recommendedLimit.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Arc Score"
              filterKey="arcScore"
              operators={NUMBER_OPERATORS}
              type="number"
              value={formValues.arcScore.value}
              operator={formValues.arcScore.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Rationale"
              filterKey="rationale"
              operators={STRING_OPERATORS}
              value={formValues.rationale.value}
              operator={formValues.rationale.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Created At"
              filterKey="createdAt"
              operators={DATE_OPERATORS}
              type="date"
              value={formValues.createdAt.value}
              operator={formValues.createdAt.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="Payment Index"
              filterKey="paymentIndex"
              operators={NUMBER_OPERATORS}
              type="number"
              value={formValues.paymentIndex.value}
              operator={formValues.paymentIndex.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
            <FilterRow 
              label="AR in Arrear"
              filterKey="arInArrear"
              operators={NUMBER_OPERATORS}
              type="number"
              value={formValues.arInArrear.value}
              operator={formValues.arInArrear.operator}
              onValueChange={handleValueChange}
              onOperatorChange={handleOperatorChange}
              onClear={handleClearFilter}
            />
          </div>
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid #e0e0e0',
          p: 2
        }}>
          <Button 
            type="button" 
            onClick={handleClearAll}
            variant="outlined"
            sx={{
                borderRadius: "6px",
                height: "40px",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "400",
              }}
          >
            Clear All
          </Button>
          <Button 
            type="submit" 
            variant="contained" 
            color="primary"
            sx={{
                background: "#5186EC 0% 0% no-repeat padding-box",
                borderRadius: "6px",
                backgroundColor: "#5186EC",
                height: "40px",
                fontSize: "14px",
                textTransform: "none",
                fontWeight: "400",
              }}
          >
            Apply Filters
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default React.memo(FilterDialog);