import { Typography } from "@mui/material";
import React, { useState,useEffect } from "react";
import getTenantIdFromURL from "./utils/getTenantIdFromURL";
import { useSelector } from "react-redux";
import RecommendationTable from "./RecommendationTable/RecommendationTable";
import { getRiskyCustomerRecommendedLimit } from "./utils/HelperRC";
import { useProspectsAR } from "./context/prospectsARContext";


const FilteredSummaryTableNew = ({adminAction}) => {
  const storedData = localStorage.getItem("filteredSummary");
  const segmentName = JSON.parse(localStorage.getItem("segmentName"));
  const rangeTypeName = JSON.parse(localStorage.getItem("rangeTypeName"));
  const metricName = JSON.parse(localStorage.getItem("metricName"));
  const tableName = `${metricName} : ${segmentName} : ${rangeTypeName}`;
  const [tenantId,setTenantId] = useState()
  const isAdmin = useSelector(state => state.userInfo.isAdmin);
  const URLTenant = getTenantIdFromURL();
  const currentTenant  = useSelector(state => state.userInfo.tenantId);
  
  const selectTenantId =()=>{
    if(isAdmin) setTenantId(URLTenant)
    else setTenantId(currentTenant)
  }
  useEffect(()=>{
    selectTenantId();
  },[isAdmin,URLTenant,currentTenant])

  let filteredSummary = [];
  if (storedData) {
    filteredSummary = JSON.parse(storedData);
  }

  const dataWithIds = filteredSummary?.map((obj, index) => {
    const { id, ...rest } = obj;

    return {
      id: index + 1,
      ...rest,
    };
  });
  dataWithIds.sort((a, b) => b.id - a.id);
   let SummaryRows = dataWithIds;

   SummaryRows = SummaryRows.filter(row => {
    return Object.keys(row).some(
      key =>
        key.includes("AR Balance") &&
        row[key] !== null &&
        row[key] !== 0 &&
        row[key] !== ""
    );
  });
  

  function calculateSortingScore(
    riskSegment,
    failureScore,
    alternativeScore,
    delinquencyScore
  ) {
    let sum = 0;
    let denominator = 0;

    if (riskSegment !== undefined) {
      sum += 5 * riskSegment;
      denominator += 5;
    }

    if (failureScore !== undefined) {
      sum += 3 * failureScore;
      denominator += 3;
    }

    if (alternativeScore !== undefined) {
      sum += 2 * alternativeScore;
      denominator += 2;
    }

    if (delinquencyScore !== undefined) {
      sum += 2 * delinquencyScore;
      denominator += 2;
    }

    if (denominator !== 0) {
      return sum / denominator;
    } else {
      // Handle the case where all scores are missing
      console.error(
        "All scores are missing. Unable to calculate sorting score."
      );
      return undefined;
    }
  }

  const getTop10RiskyCustomer = (data) => {
    const processedData = data.map((item) => {
      const riskSegment = parseFloat(item["Risk Segment"]);
      const failureScore = parseFloat(item["Failure Score"]);
      const alternativeScore = parseFloat(item["Alternative Score"]);
      const delinquencyScore = parseFloat(item["Delinquency Score"]);

      // Check for NaN and set SortingScore accordingly
      const sortingScore = calculateSortingScore(
        isNaN(riskSegment) ? undefined : riskSegment,
        isNaN(failureScore) ? undefined : failureScore,
        isNaN(alternativeScore) ? undefined : alternativeScore,
        isNaN(delinquencyScore) ? undefined : delinquencyScore
      );

      return { ...item, SortingScore: sortingScore };
    });

    const sortedData = processedData.slice().sort((a, b) => {
      const scoreA = a.SortingScore || 0;
      const scoreB = b.SortingScore || 0;

      return scoreB - scoreA;
    });

    const top10RiskyCompanies = sortedData.slice(0, 10).map((item, index) => ({
      ...item,
      Rank: index + 1,
    }));

    return top10RiskyCompanies;
  };



  let gridRowsRC = [];
    const dataArray = getRiskyCustomerRecommendedLimit(
      SummaryRows,
      0
    );
    gridRowsRC = getTop10RiskyCustomer(dataArray);

    const sortGridRows = (gridRowsRC) => {
      return gridRowsRC
        .sort((a, b) => {
          // Initial lexicographic sort by name
          const nameA = a["Company Name"] ? (String)(a["Company Name"]).toUpperCase() : ""; // Ignore case
          const nameB = b["Company Name"] ? (String)(b["Company Name"]).toUpperCase() : ""; // Ignore case
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .sort((a, b) => {
          // Sort by Paydex score
          if(a['Paydex'] != null || b['Paydex'] != null){
            return (parseInt(b['Paydex']) || 0) - (parseInt(a['Paydex']) || 0);
          }
  
          if(a['Combined Score'] != null || b['Combined Score'] != null){
            return (parseInt(b['Combined Score']) || 0) - (parseInt(a['Combined Score']) || 0);
          }
    
          return 0;
        });
    };

    console.log("SummaryRows",SummaryRows)
  return (<>
    <RecommendationTable rows={SummaryRows} showAtRisk={false} setShowAtRisk={()=>{}} riskyRows={sortGridRows(gridRowsRC)} filteredSummary = {true} tableName={tableName} tenantIdp={tenantId}></RecommendationTable>
  </>
  );
};

export default FilteredSummaryTableNew;
