import {
  RecommendedNetRange,
  UnderwritingRange,
} from "./GetRangeForRIskyCustomer";
import { underwritingReasons } from "./SegmentationTypes";

function checkFormat(value) {
  const numericValue = parseFloat(value);
  return !isNaN(numericValue) ? numericValue : 0;
}

export const getRiskyCustomerRecommendedLimit = (data, rangeId) => {
  let rangeValues;
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      rangeValues = [0, 49999];
      break;
    case 2:
      rangeValues = [50000, 99999];
      break;
    case 3:
      rangeValues = [100000, 149000];
      break;
    case 4:
      rangeValues = [150000, 199999];
      break;
    case 5:
      rangeValues = [200000, 249000];
      break;
    case 6:
      rangeValues = [250000, 299999];
      break;
    case 7:
      rangeValues = [300000, Infinity];
      break;
    default:
      return []; // Return an empty array for invalid rangeId
  }

  return data.filter((item) => {
    const recommendedLimit = checkFormat(item["Recommended Limit"]);
    return (
      recommendedLimit >= rangeValues[0] && recommendedLimit <= rangeValues[1]
    );
  });
};

export const getRiskyCustomerRNOrCN = (data, rangeId, segment) => {
  const ranges = RecommendedNetRange;

  let rangeValue;

  if (rangeId === 0) {
    return data; // Return all data
  } else if (rangeId >= 1 && rangeId <= 6) {
    rangeValue = ranges[rangeId].label;
  } else if (rangeId === 7) {
    const excludedRangeLabels = ranges.slice(1, 7).map((range) => range.label);
    return data.filter((item) => !excludedRangeLabels.includes(item[segment]));
  } else {
    return []; // Return an empty array for invalid rangeId
  }

  return data.filter((item) => item[segment] === rangeValue);
};

export const getRiskyCustomerUnderwriting = (data, rangeId) => {
  const ranges = UnderwritingRange;

  if (rangeId === 0) {
    return data; // Return all data
  } else if (rangeId >= 1 && rangeId <= 3) {
    const rangeLabel = ranges[rangeId].label;
    return data.filter((item) => item["Underwriting"] === rangeLabel);
  } else if (rangeId === 4) {
    const includedRangeLabels = ranges.slice(1, 4).map((range) => range.label);
    return data.filter(
      (item) => !includedRangeLabels.includes(item["Underwriting"])
    );
  } else {
    return []; // Return an empty array for invalid rangeId
  }
};

export const getRiskyCustomerScore = (data, rangeId, segment) => {
  let filterFunction;
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 0 && parseFloat(item[segment]) <= 1;
      break;
    case 2:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 1 && parseFloat(item[segment]) <= 2;
      break;
    case 3:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 2 && parseFloat(item[segment]) <= 3;
      break;
    case 4:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 3 && parseFloat(item[segment]) <= 4;
      break;
    case 5:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 4 && parseFloat(item[segment]) <= 5;
      break;
    case 6:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 5 && parseFloat(item[segment]) <= 6;
      break;
    case 7:
      filterFunction = (item) =>
        parseFloat(item[segment]) > 6 && parseFloat(item[segment]) <= 7;
      break;
    case 8:
      filterFunction = (item) => parseFloat(item[segment]) > 7;
      break;
    case 9:
      filterFunction = (item) => isNaN(parseFloat(item[segment]));
      break;
    default:
      return []; // Return an empty array for invalid rangeId
  }

  return data.filter(filterFunction);
};

export const getRiskyCustomerAging = (data, rangeId, segment) => {
  let filterFunction;
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      filterFunction = (item) => checkFormat(item[segment]) <= 0;
      break;
    case 2:
      filterFunction = (item) => checkFormat(item[segment]) > 0;
      break;
    default:
      return []; // Return an empty array for invalid rangeId
  }

  return data.filter(filterFunction);
};

export const getRiskyCustomerCombinedAging = (data, rangeId) => {
  if (rangeId === 0) {
    return data; // Return the original data
  } else if (rangeId === 1) {
    return data.filter(
      (item) =>
        checkFormat(item["AR Balance Aging 30"]) +
          checkFormat(item["AR Balance Aging 60"]) +
          checkFormat(item["AR Balance Aging 90"]) +
          checkFormat(item["AR Balance Aging 90+"]) <=
        0
    );
  } else if (rangeId === 2) {
    return data.filter(
      (item) =>
        checkFormat(item["AR Balance Aging 30"]) +
          checkFormat(item["AR Balance Aging 60"]) +
          checkFormat(item["AR Balance Aging 90"]) +
          checkFormat(item["AR Balance Aging 90+"]) >
        0
    );
  } else {
    return "Invalid rangeId"; // Handle invalid rangeId
  }
};

export const getRiskyCustomerCARBOrCL = (data, rangeId, segment) => {
  let filterFunction;
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      filterFunction = (item) =>
        checkFormat(item[segment]) < 20000 && checkFormat(item[segment]) >= 0;
      break;
    case 2:
      filterFunction = (item) =>
        checkFormat(item[segment]) < 50000 &&
        checkFormat(item[segment]) >= 20000;
      break;
    case 3:
      filterFunction = (item) =>
        checkFormat(item[segment]) < 150000 &&
        checkFormat(item[segment]) >= 50000;
      break;
    case 4:
      filterFunction = (item) =>
        checkFormat(item[segment]) < 250000 &&
        checkFormat(item[segment]) >= 150000;
      break;
    case 5:
      filterFunction = (item) =>
        checkFormat(item[segment]) < 500000 &&
        checkFormat(item[segment]) >= 250000;
      break;
    case 6:
      filterFunction = (item) => checkFormat(item[segment]) >= 500000;
      break;
    default:
      return []; // Return an empty array for invalid rangeId
  }

  return data.filter(filterFunction);
};

export const getRiskyCustomerRiskSegment = (data, rangeId) => {
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      return data.filter(
        (item) => item["Risk Segment"] <= 2.5 && item["Risk Segment"] >= 0
      );
    case 2:
      return data.filter(
        (item) => item["Risk Segment"] <= 5 && item["Risk Segment"] > 2.5
      );
    case 3:
      return data.filter(
        (item) => item["Risk Segment"] <= 7.5 && item["Risk Segment"] > 5
      );
    case 4:
      return data.filter((item) => item["Risk Segment"] > 7.5);
    default:
      return []; // Return an empty array for an invalid rangeId
  }
};
export const getRiskyCustomerRiskScore = (data, rangeId) => {
  switch (rangeId) {
    case 0:
      return data; 
    case 1:
      return data.filter(
        (item) => item["General Risk Score"] >= 1 && item["General Risk Score"] < 4
      );
    case 2:
      return data.filter(
        (item) => item["General Risk Score"] >= 4 && item["General Risk Score"] < 7
      );
    case 3:
      return data.filter(
        (item) => item["General Risk Score"] >= 7 && item["General Risk Score"] <= 10
      );
    default:
      return []; 
  }
};

export const getRiskyCustomerPaydex = (data, rangeId) => {
  switch (rangeId) {
    case 0:
      return data; 
    case 1:
      return data.filter(
        (item) => item["Paydex"] >= 1 && item["Paydex"] < 4
      );
    case 2:
      return data.filter(
        (item) => item["Paydex"] >= 4 && item["Paydex"] < 7
      );
    case 3:
      return data.filter(
        (item) => item["Paydex"] >= 7
      );
    default:
      return []; 
  }
};


export const getRiskyCustomerCountryScore = (data, rangeId) => {
  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      return data.filter((item) => item["Country Score"] === 1);
    case 2:
      return data.filter((item) => item["Country Score"] === 2);
    case 3:
      return data.filter((item) => item["Country Score"] === 3);
    case 4:
      return data.filter((item) => item["Country Score"] === 4);
    case 5:
      return data.filter((item) => item["Country Score"] === 5);
    case 6:
      return data.filter((item) => item["Country Score"] === 6);
    case 7:
      return data.filter((item) => item["Country Score"] === 7);
    case 8:
      return data.filter((item) => item["Country Score"] > 7);
    default:
      return []; // Return an empty array for an invalid rangeId
  }
};

export const getRiskyCustomerUnderwritingReason = (data, rangeId) => {
  const UWR = [
    "Requested limit exceeds max limit.",
    "Risk ind > 3.",
    "Approved",
    "Missing risk segment.",
    "Approved for low limits.",
    "Buyer currently overdue.",
    "Country rating too high.",
  ];

  switch (rangeId) {
    case 0:
      return data; // Return all data
    case 1:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val1
      );
    case 2:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val2
      );
    case 3:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val3
      );
    case 4:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val4
      );
    case 5:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val5
      );
    case 6:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val6
      );
    case 7:
      return data.filter(
        (item) => item["Underwriting Reason"] === underwritingReasons.val7
      );
    case 8:
      return data.filter((item) => !UWR.includes(item["Underwriting Reason"]));
    default:
      return []; // Return an empty array for an invalid rangeId
  }
};
